var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M11.822 1C7.502 1 4 4.587 4 8.975c0 2.198.917 3.699 1.933 4.935.917 1.115 1.89 2.07 2.566 3.346a.734.734 0 00-.099.366v2.445a.733.733 0 00.733.733h.49v1.467a.733.733 0 00.733.733h2.933a.733.733 0 00.733-.733V20.8h.49a.733.733 0 00.732-.733v-2.445a.733.733 0 00-.099-.366c.676-1.276 1.65-2.23 2.567-3.346 1.016-1.236 1.932-2.737 1.932-4.935C19.644 4.587 16.142 1 11.822 1zm0 1.467c3.51 0 6.356 2.896 6.356 6.508 0 1.813-.661 2.865-1.597 4.003-.903 1.098-2.093 2.217-2.902 3.911h-1.123v-6.6h.733a.732.732 0 00.687-1.016.733.733 0 00-.687-.45h-2.933a.737.737 0 00-.73.487.734.734 0 00.73.979h.733v6.6H9.966c-.81-1.694-2-2.813-2.903-3.911-.935-1.138-1.596-2.19-1.596-4.003 0-3.612 2.845-6.508 6.355-6.508zM9.867 18.356h3.91v.977h-3.91v-.977zm1.222 2.444h1.467v.733h-1.467V20.8z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }