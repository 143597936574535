































import { defineComponent } from '@nuxtjs/composition-api'

import ChatIcon from '@/assets/img/company-chat-placeholder.svg'
import CloseIcon from '@/assets/img/close.svg'

export default defineComponent({
    components: {
        ChatIcon,
        CloseIcon
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        closable: {
            type: Boolean,
            default: false
        }
    }
})
