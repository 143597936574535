


















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { getFullName } from '@/composables/user'

export default defineComponent({
    props: {
        name: {
            type: String,
            default: ''
        },
        familyName: {
            type: String,
            default: ''
        },
        noName: {
            type: String,
            default: 'Пользователь Workle Pro'
        },
        mainImage: {
            type: String,
            default: ''
        },
        align: {
            type: String as PropType<'left' | 'right'>,
            default: 'left'
        }
    },
    setup: (props) => {
        const fullName = computed(() => getFullName(
            props,
            props.noName
        ))

        return {
            fullName
        }
    }
})
