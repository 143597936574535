













































































































































import {
    computed,
    defineComponent,
    ref,
    watch,
    nextTick,
    PropType,
    useContext
} from '@nuxtjs/composition-api'

import { Components as Customer, OperationMethods } from '@/types/client.customer'

import { Message } from '@/interfaces/message.interface'
import {
    managerAdmins,
    openedChannel,
    fetchManagersMethod
} from '@/composables/admin/cabinets'

import { generalizeLinkImage } from '@/composables/images'

import CloseIcon from '@/assets/img/close.svg'
import PaperPlaneIcon from '@/assets/img/paper-plane.svg'
import LinkIcon from '@/assets/img/link.svg'
import ChangeManagerIcon from '@/assets/img/admin/change-manager.svg'
import PaperclipIcon from '@/assets/img/paperclip.svg'
import CheckIcon from '@/assets/img/default-check.svg'

import AppDropdown from '@/components/ui/AppDropdown.vue'

export default defineComponent({
    components: {
        PaperPlaneIcon,
        CloseIcon,
        LinkIcon,
        ChangeManagerIcon,
        PaperclipIcon,
        CheckIcon
    },
    props: {
        value: {
            type: String,
            required: true
        },
        uploadMethod: {
            type: Function as PropType<OperationMethods[]>,
            default: null
        },
        placeholder: {
            type: String,
            default: 'Напишите сообщение'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        minLength: {
            type: Number,
            default: 1
        },
        maxLength: {
            type: Number,
            default: 4000
        },
        selectedMessage: {
            type: Object as PropType<Message | null>,
            default: null
        },
        selectedTitle: {
            type: String,
            default: 'Выбранное сообщение'
        },
        sendButtonCaption: {
            type: String,
            default: 'Отправить'
        },
        showFastLinkButton: {
            type: Boolean,
            default: true
        },
        showEmojiPicker: {
            type: Boolean,
            default: true
        },
        showFileUpload: {
            type: Boolean,
            default: true
        },
        showChangeManagerButton: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const $core = ref<HTMLElement | null>(null)
        const length = computed(() => props.value.length)
        const { $apiAdmin } = useContext()

        const $dropdown = ref<InstanceType<typeof AppDropdown> | null>(null)
        const searchManager = ref<string>('')
        const managers = ref()

        const image = ref<Customer.Schemas.UploadedFile>()

        const onLinkFastFill = () => {
            const position = $core?.value?.selectionStart

            const prepend = props.value.substring(0, position)
            const append = props.value.substring(position, props.value.length)
            const result = `${prepend}<a href="https://www.ссылка" target="_blank">Текст</a>${append}`

            emit('input', result)
            $core.value?.focus()
        }

        const openChangeManagerDropdown = () => $dropdown.value?.toggle()

        const uploadImageFile = async (
            wrapper,
            setProgress
        ) => {
            const formData = new FormData()
            formData.append('file', wrapper.file, wrapper.file.name)

            const { data } = await props.uploadMethod(
                null,
                formData,
                {
                    onUploadProgress: (progressEvent: ProgressEvent) => setProgress(
                        progressEvent.loaded,
                        progressEvent.total
                    ),
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )

            return data
        }

        const onClearFile = () => {
            image.value = undefined
        }

        const autoResize = () => {
            if (!$core.value) return undefined

            $core.value.style.height = '0px'
            $core.value.style.height = `${$core.value.scrollHeight}px`
            $core.value.scrollBy({ top: $core.value.scrollHeight })
        }

        const disabledByText = computed(
            () => props.minLength > length.value || length.value > props.maxLength
        )

        const onSubmit = () => {
            if (image.value?.sourceFileName) {
                const stringObject = JSON.stringify({
                    fileKey: generalizeLinkImage(String(image.value.fileKey)),
                    sourceFileName: image.value.sourceFileName
                })

                emit('input', stringObject)
                emit('on-submit', props.value)

                image.value = undefined

                return undefined
            }

            if (!props.value.trim()) return undefined

            emit('on-submit', props.value)
        }

        const clearInput = () => {
            emit('input', '')

            if ($core.value) {
                $core.value.style.height = ''
            }
        }

        const onInput = (event: Event) => {
            const target = event.target as HTMLInputElement

            emit('input', target.value)
        }

        const focus = () => nextTick(() => {
            $core.value?.focus()
        })

        const pickEmoji = (code: string) => {
            const position = $core?.value?.selectionStart

            const prepend = props.value.substring(0, position)
            const append = props.value.substring(position, props.value.length)
            const result = `${prepend}${code}${append}`

            emit('input', result)
            $core.value?.focus()
        }

        const selectManager = async (manager) => {
            $dropdown.value?.close()
            await $apiAdmin.worklegramChannelsChangeManager({
                channelId: openedChannel.value as string
            }, {
                newManagerId: manager.id
            })

            await fetchManagersMethod.value()
        }

        const filteredManagers = computed(() => managerAdmins.value
            .filter(manager => manager.fullName
                ?.toUpperCase()
                .includes(searchManager.value.toUpperCase())))

        watch(
            () => props.disabled,
            () => {
                if (props.disabled) return undefined

                focus()
            }
        )

        watch(
            () => props.value,
            () => nextTick(autoResize),
            { immediate: true }
        )

        const onCancel = () => {
            clearInput()
            emit('on-cancel')
        }

        return {
            $core,
            $dropdown,
            image,
            disabledByText,
            searchManager,
            managers,
            managerAdmins,
            filteredManagers,
            pickEmoji,
            onLinkFastFill,
            clearInput,
            autoResize,
            onSubmit,
            onCancel,
            onInput,
            focus,
            uploadImageFile,
            onClearFile,
            openChangeManagerDropdown,
            selectManager
        }
    }
})
