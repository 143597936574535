var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.868 1.603l1.61 2.79a.24.24 0 01-.083.325c-1.566.95-3.508 2.513-3.86 4.572-.194 1.14.132 2.318.971 3.508.168-.852.716-1.813 1.57-2.73.977-1.048 2.209-1.88 3.216-2.17a.239.239 0 01.274.11l1.611 2.79 2.21-7.406-7.519-1.79zM6.695 13.68a.24.24 0 01-.186-.088C5.303 12.124 4.816 10.65 5.062 9.21 5.43 7.06 7.332 5.442 8.946 4.43L7.173 1.36a.242.242 0 01.016-.264.241.241 0 01.248-.09l8.31 1.976a.24.24 0 01.174.303l-2.443 8.184a.24.24 0 01-.438.052l-1.793-3.106c-1.935.668-4.405 3.224-4.313 5.01a.24.24 0 01-.24.254zM9.408 20.608l7.52 1.79-1.611-2.79a.24.24 0 01.083-.326c1.567-.95 3.508-2.512 3.86-4.571.195-1.14-.131-2.319-.97-3.508-.168.851-.716 1.813-1.57 2.729-.977 1.048-2.21 1.88-3.216 2.17a.24.24 0 01-.275-.11l-1.61-2.79-2.21 7.406zM17.415 23a.242.242 0 01-.056-.006L9.05 21.017a.24.24 0 01-.175-.302l2.443-8.185a.24.24 0 01.438-.051l1.793 3.106c1.936-.669 4.405-3.224 4.313-5.012a.24.24 0 01.425-.165c1.206 1.469 1.693 2.943 1.447 4.383-.367 2.149-2.27 3.767-3.884 4.778l1.772 3.07a.24.24 0 01-.207.361z",
      "fill": "inherit",
      "stroke": "inherit"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }