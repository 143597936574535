























import { RawLocation } from 'vue-router'

import { PropType, defineComponent, useContext } from '@nuxtjs/composition-api'

import { CatalogItem as CatalogItemInterface } from '@/interfaces/catalog.interface'

import CatalogProfile from './-CatalogProfile.vue'
import CatalogLogoutButton from './-CatalogLogoutButton.vue'

export default defineComponent({
    components: {
        CatalogProfile,
        CatalogLogoutButton
    },
    props: {
        profileLink: {
            type: [ String, Object ] as PropType<RawLocation | null>,
            default: null
        },
        catalogItems: {
            type: Array as PropType<CatalogItemInterface[]>,
            default: () => []
        }
    },
    setup: () => {
        const { $auth } = useContext()

        return {
            logout: $auth.logout
        }
    }
})
