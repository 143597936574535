var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('catalog-item', _vm._b({
    staticClass: "catalog-logout-button",
    on: {
      "on-click": _vm.logout
    }
  }, 'catalog-item', _vm.$attrs, false), [_c('logout-icon', {
    staticClass: "catalog-logout-button__icon",
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  }), _vm._v(" "), _vm._t("default", function () {
    return [_vm._v("\n        Выход\n    ")];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }