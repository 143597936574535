import { DateRange } from "@/composables/dates";
import { ref } from "@nuxtjs/composition-api";
import moment from "moment";

type paramTypes = boolean | null | undefined

export const isMassMailing = ref(false)
export const channelIds = ref<string[]>([])
export const searchQuery = ref()
export const currentSegment = ref(null)
export const pickedManagersList = ref([])
export const period = ref<DateRange | null>({
    from: moment().startOf('day').subtract(1, 'month').toISOString(),
    to: moment().endOf('day').toISOString()
})
export const fetchParam = ref({
    isLongWaiting: null as paramTypes,
    isUnreaded: null as paramTypes
})
