var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11 2.176a8.824 8.824 0 100 17.648 8.824 8.824 0 000-17.648zM7.314 6.947c.422 0 .805.172 1.081.448 0 .013.448.392.448 1.082 0 .69-.448 1.069-.448 1.082-.96.96-2.61.276-2.61-1.082 0-.842.687-1.53 1.529-1.53zm7.372 0c1.355 0 2.046 1.646 1.08 2.611-.6.598-1.562.597-2.16-.001l-.001.001a1.53 1.53 0 010-2.163v.001c.279-.277.66-.449 1.081-.449zM7.6 16.587a6.57 6.57 0 01-2.395-2.554.588.588 0 01.501-.897h10.588v.002c.43 0 .725.46.521.855-1.735 3.366-5.97 4.574-9.215 2.594zM3.93 3.93C7.834.024 14.166.024 18.07 3.929c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0-3.905-3.905-3.905-10.237 0-14.142z",
      "fill": "#ADADAD"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.564 8.726v.001a.359.359 0 000-.501v.001m7.372 0a.355.355 0 01.002.5c-.216.215-.502.032-.502 0M11 2.176a8.824 8.824 0 100 17.647 8.824 8.824 0 000-17.648zm-3.686 4.77c.422 0 .805.172 1.081.448 0 .013.448.392.448 1.082 0 .69-.448 1.069-.448 1.082-.96.96-2.61.276-2.61-1.082 0-.842.687-1.53 1.529-1.53zm7.372 0c1.355 0 2.046 1.646 1.08 2.611-.6.598-1.562.597-2.16-.001l-.001.001a1.53 1.53 0 010-2.163v.001c.279-.277.66-.449 1.081-.449zM7.6 16.587a6.57 6.57 0 01-2.395-2.554.588.588 0 01.501-.897h10.588v.002c.43 0 .725.46.521.855-1.735 3.366-5.97 4.574-9.215 2.594zM3.93 3.93C7.834.024 14.166.024 18.07 3.929c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0-3.905-3.905-3.905-10.237 0-14.142z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }