var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 17 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M16.08 8.288L9.189 15.18a4.502 4.502 0 01-6.367-6.367L9.713 1.92a3.002 3.002 0 114.245 4.245l-6.9 6.893a1.501 1.501 0 01-2.122-2.123l6.367-6.36"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }