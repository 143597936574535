import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f3e0101 = () => interopDefault(import('../src/pages/partner/login-as-claim.vue' /* webpackChunkName: "pages/partner/login-as-claim" */))
const _1fd14250 = () => interopDefault(import('../src/pages/partner/offer.vue' /* webpackChunkName: "pages/partner/offer" */))
const _89d4b00a = () => interopDefault(import('../src/pages/partner/office/index.vue' /* webpackChunkName: "pages/partner/office/index" */))
const _6ca4d152 = () => interopDefault(import('../src/pages/partner/signup/index.vue' /* webpackChunkName: "pages/partner/signup/index" */))
const _57b02bfe = () => interopDefault(import('../src/pages/partner/auth/login.vue' /* webpackChunkName: "pages/partner/auth/login" */))
const _5d5c1750 = () => interopDefault(import('../src/pages/partner/auth/success.vue' /* webpackChunkName: "pages/partner/auth/success" */))
const _17260528 = () => interopDefault(import('../src/pages/partner/office/calculator/index.vue' /* webpackChunkName: "pages/partner/office/calculator/index" */))
const _7676cfe9 = () => interopDefault(import('../src/pages/partner/office/company/index.vue' /* webpackChunkName: "pages/partner/office/company/index" */))
const _509ed3d6 = () => interopDefault(import('../src/pages/partner/office/profile/index.vue' /* webpackChunkName: "pages/partner/office/profile/index" */))
const _29b16fbf = () => interopDefault(import('../src/pages/partner/office/rates/index.vue' /* webpackChunkName: "pages/partner/office/rates/index" */))
const _8c64eb5c = () => interopDefault(import('../src/pages/partner/office/showcases/index.vue' /* webpackChunkName: "pages/partner/office/showcases/index" */))
const _39c1ce4d = () => interopDefault(import('../src/pages/partner/office/statistics/index.vue' /* webpackChunkName: "pages/partner/office/statistics/index" */))
const _f8f4d6e4 = () => interopDefault(import('../src/pages/partner/office/welcome/index.vue' /* webpackChunkName: "pages/partner/office/welcome/index" */))
const _0a65ded0 = () => interopDefault(import('../src/pages/partner/office/calculator/-CalculatorForm/index.vue' /* webpackChunkName: "pages/partner/office/calculator/-CalculatorForm/index" */))
const _b98488f6 = () => interopDefault(import('../src/pages/partner/office/documents/edo/index.vue' /* webpackChunkName: "pages/partner/office/documents/edo/index" */))
const _669a5e07 = () => interopDefault(import('../src/pages/partner/office/documents/invoices/index.vue' /* webpackChunkName: "pages/partner/office/documents/invoices/index" */))
const _33faa0b1 = () => interopDefault(import('../src/pages/partner/office/documents/offer/index.vue' /* webpackChunkName: "pages/partner/office/documents/offer/index" */))
const _640c10ac = () => interopDefault(import('../src/pages/partner/office/showcases/-ShowcasesLinkParams/index.vue' /* webpackChunkName: "pages/partner/office/showcases/-ShowcasesLinkParams/index" */))
const _9c3dbad2 = () => interopDefault(import('../src/pages/partner/office/showcases/history/index.vue' /* webpackChunkName: "pages/partner/office/showcases/history/index" */))
const _2d771d72 = () => interopDefault(import('../src/pages/partner/office/documents/invoices/_id/index.vue' /* webpackChunkName: "pages/partner/office/documents/invoices/_id/index" */))
const _5f470874 = () => interopDefault(import('../src/pages/partner/office/showcases/product-edit/_id/index.vue' /* webpackChunkName: "pages/partner/office/showcases/product-edit/_id/index" */))
const _6c756d18 = () => interopDefault(import('../src/pages/partner/office/showcases/showcase-edit/_id/index.vue' /* webpackChunkName: "pages/partner/office/showcases/showcase-edit/_id/index" */))
const _f8c22ad0 = () => interopDefault(import('../src/pages/partner/office/statistics/product/_id/index.vue' /* webpackChunkName: "pages/partner/office/statistics/product/_id/index" */))
const _66bc6078 = () => interopDefault(import('../src/pages/partner/office/statistics/product/_id/request/_requestId/index.vue' /* webpackChunkName: "pages/partner/office/statistics/product/_id/request/_requestId/index" */))
const _1f552c3d = () => interopDefault(import('../src/pages/partner/office/showcases/_id/index.vue' /* webpackChunkName: "pages/partner/office/showcases/_id/index" */))
const _7bc825c6 = () => interopDefault(import('../src/pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login-as-claim",
    component: _6f3e0101,
    name: "login-as-claim"
  }, {
    path: "/offer",
    component: _1fd14250,
    name: "offer"
  }, {
    path: "/office",
    component: _89d4b00a,
    name: "office"
  }, {
    path: "/signup",
    component: _6ca4d152,
    name: "signup"
  }, {
    path: "/auth/login",
    component: _57b02bfe,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _5d5c1750,
    name: "auth-success"
  }, {
    path: "/office/calculator",
    component: _17260528,
    name: "office-calculator"
  }, {
    path: "/office/company",
    component: _7676cfe9,
    name: "office-company"
  }, {
    path: "/office/profile",
    component: _509ed3d6,
    name: "office-profile"
  }, {
    path: "/office/rates",
    component: _29b16fbf,
    name: "office-rates"
  }, {
    path: "/office/showcases",
    component: _8c64eb5c,
    name: "office-showcases"
  }, {
    path: "/office/statistics",
    component: _39c1ce4d,
    name: "office-statistics"
  }, {
    path: "/office/welcome",
    component: _f8f4d6e4,
    name: "office-welcome"
  }, {
    path: "/office/calculator/-CalculatorForm",
    component: _0a65ded0,
    name: "office-calculator--CalculatorForm"
  }, {
    path: "/office/documents/edo",
    component: _b98488f6,
    name: "office-documents-edo"
  }, {
    path: "/office/documents/invoices",
    component: _669a5e07,
    name: "office-documents-invoices"
  }, {
    path: "/office/documents/offer",
    component: _33faa0b1,
    name: "office-documents-offer"
  }, {
    path: "/office/showcases/-ShowcasesLinkParams",
    component: _640c10ac,
    name: "office-showcases--ShowcasesLinkParams"
  }, {
    path: "/office/showcases/history",
    component: _9c3dbad2,
    name: "office-showcases-history"
  }, {
    path: "/office/documents/invoices/:id",
    component: _2d771d72,
    name: "office-documents-invoices-id"
  }, {
    path: "/office/showcases/product-edit/:id",
    component: _5f470874,
    name: "office-showcases-product-edit-id"
  }, {
    path: "/office/showcases/showcase-edit/:id",
    component: _6c756d18,
    name: "office-showcases-showcase-edit-id"
  }, {
    path: "/office/statistics/product/:id",
    component: _f8c22ad0,
    name: "office-statistics-product-id"
  }, {
    path: "/office/statistics/product/:id?/request/:requestId",
    component: _66bc6078,
    name: "office-statistics-product-id-request-requestId"
  }, {
    path: "/office/showcases/:id",
    component: _1f552c3d,
    name: "office-showcases-id"
  }, {
    path: "/",
    component: _7bc825c6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
