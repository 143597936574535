





















































import moment from 'moment'

import {
    PropType,
    computed,
    defineComponent,
    onMounted,
    ref,
    watch
} from '@nuxtjs/composition-api'

import { Message } from '@/interfaces/message.interface'

import { getFullName } from '@/composables/user'
import { openModal } from '@/composables/result-modal'
import {
    urlRegExp,
    httpLinkRegExp,
    imageExtensionRegExp,
    fileExtensionRegExp
} from '@/composables/regexps'

import FileIcon from '@/assets/img/file.svg'

import ChatMessageQuote from './-ChatMessageQuote.vue'

type File = {
    isFile: Boolean,
    fileType: 'image' | 'file' | undefined
    fileLink: string | null | undefined
    sourceFileName: string | null | undefined
}

export default defineComponent({
    components: {
        ChatMessageQuote,
        FileIcon
    },
    props: {
        type: {
            type: String,
            default: 'white',
            validator: (value: string) => {
                const allowed: string[] = [ 'white', 'black' ]

                if (!allowed.includes(value)) {
                    console.error(
                        `<ChatMessage>: property "type" must be one of ${allowed.join(',')}`
                    )
                    return false
                }

                return true
            }
        },
        message: {
            type: Object as PropType<Message>,
            default: () => ({})
        },
        parseLinks: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: 'left',
            validator: (value: string) => {
                const allowed: string[] = [ 'left', 'right' ]

                if (!allowed.includes(value)) {
                    console.error(
                        `<ChatMessage>: property "position" must be one of ${allowed.join(',')}`
                    )

                    return false
                }

                return true
            }
        }
    },
    setup: (props) => {
        const fileParams = ref<File>({
            isFile: false,
            fileType: undefined,
            fileLink: undefined,
            sourceFileName: undefined
        })

        const isEdited = computed(
            () => props.message.dateCreated !== props.message.dateUpdated
                && props.message.editStatus !== 'New'
        )

        const formattedTime = computed(() => {
            const actualDate = isEdited.value
                ? props.message.dateUpdated
                : props.message.dateCreated

            return moment(actualDate).format('HH:mm')
        })

        const formattedText = computed(() => {
            let result = props.message.message || props.message.text || ''

            if (props.parseLinks) {
                result = result.replace(
                    urlRegExp,
                    match => `<a href="${match}" target="_blank">${match}</a>`
                )
            }

            result = result.replaceAll(
                '\n',
                '<br>'
            )

            return result
        })

        const checkFileType = (fileKey: string) => {
            if (imageExtensionRegExp.test(fileKey)) {
                return 'image'
            }

            if (fileExtensionRegExp.test(fileKey)) {
                return 'file'
            }
        }

        const getFileInfoFromMessage = () => {
            const message = props.message.message || props.message.text || ''

            try {
                const getFileInfo = JSON.parse(message)

                if (
                    httpLinkRegExp.test(getFileInfo.fileKey)
                    && (imageExtensionRegExp.test(getFileInfo.fileKey)
                        || fileExtensionRegExp.test(getFileInfo.fileKey))
                    && getFileInfo.fileKey
                ) {
                    fileParams.value.isFile = true
                    fileParams.value.fileType = checkFileType(getFileInfo.fileKey)
                    fileParams.value.fileLink = getFileInfo.fileKey
                    fileParams.value.sourceFileName = getFileInfo.sourceFileName
                }
            } catch (error) {
                if (
                    httpLinkRegExp.test(message)
                    && (imageExtensionRegExp.test(message)
                        || fileExtensionRegExp.test(message))
                ) {
                    fileParams.value.isFile = true
                    fileParams.value.fileType = checkFileType(message)
                    fileParams.value.fileLink = message
                }
            }
        }

        const respondedTitle = computed(
            () => getFullName(props.message.respondedTo?.userProfileDetails?.profile)
        )

        const responededMessage = computed(
            () => props.message.respondedTo?.message || props.message.respondedTo?.text
        )

        const openWarnModal = (fileLink: string) => {
            openModal(
                {
                    buttonCaption: 'Хорошо',
                    type: 'warning',
                    title: 'Файл будет загружен на Ваше устройство',
                    async onSubmit () {
                        window.open(fileLink)
                    }
                }
            )
        }

        watch(
            () => props.message,
            () => getFileInfoFromMessage()
        )

        onMounted(() => {
            getFileInfoFromMessage()
        })

        return {
            fileParams,
            isEdited,
            formattedText,
            formattedTime,
            respondedTitle,
            responededMessage,
            openWarnModal,
            getFullName
        }
    }
})
