var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M8.003 14.666a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333zM8 5.333v5.333M5.336 8h5.333",
      "stroke": "inherit",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }