var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('circle', {
    attrs: {
      "cx": "16",
      "cy": "16",
      "r": "16",
      "fill": "#049FFF"
    }
  }), _c('path', {
    attrs: {
      "d": "M23.877 20.044c1.74-2.468 1.473-5.85-.902-8.052a7.004 7.004 0 00-3.965-1.808.55.55 0 00-.029-.034C17.681 8.784 15.812 8 13.855 8 10.131 8 7 10.806 7 14.4c0 1.26.387 2.468 1.122 3.51l-1.027 3.261a.537.537 0 00.161.569c.162.14.391.166.58.069l3.123-1.606c.642.28 1.322.462 2.026.546 1.359 1.445 3.237 2.184 5.159 2.184.999 0 1.994-.206 2.895-.597l3.123 1.606a.521.521 0 00.24.058c.355 0 .61-.35.502-.695l-1.027-3.26zm-12.705-.914a.522.522 0 00-.465.006L8.483 20.28l.726-2.306a.538.538 0 00-.087-.49A5.009 5.009 0 018.055 14.4c0-2.94 2.602-5.333 5.8-5.333 1.288 0 2.528.394 3.534 1.105-3.387.344-6.1 3.02-6.1 6.361 0 1.04.263 2.04.76 2.936-.3-.09-.594-.203-.877-.34zm10.12 2.14a.522.522 0 00-.465-.007 6.23 6.23 0 01-2.683.604c-3.198 0-5.8-2.393-5.8-5.334 0-2.94 2.601-5.333 5.8-5.333 3.198 0 5.8 2.393 5.8 5.333 0 1.113-.369 2.18-1.067 3.083a.538.538 0 00-.087.49l.726 2.307-2.224-1.144z",
      "fill": "#fff",
      "stroke": "#fff",
      "stroke-width": ".3"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.3 17a.5.5 0 100-1 .5.5 0 000 1zM18.3 17a.5.5 0 100-1 .5.5 0 000 1zM21.3 17a.5.5 0 100-1 .5.5 0 000 1z",
      "fill": "#fff",
      "stroke": "#fff"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }