













import { defineComponent, ref } from '@nuxtjs/composition-api'

import EmojiBodyArea from '@/components/ui/AppEmoji/-AppEmojiBodyArea.vue'

import EmojiIcon from '@/assets/img/emoji-icon.svg'

export default defineComponent({
    components: {
        EmojiIcon,
        EmojiBodyArea
    },
    setup: () => {
        const $emojiPicker = ref<InstanceType<typeof EmojiBodyArea> | null>(null)

        const openEmojiPicker = (event: PointerEvent) => {
            $emojiPicker.value?.open(event)
        }

        return {
            $emojiPicker,
            openEmojiPicker
        }
    }

})
