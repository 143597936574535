var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M14.802 7.469a.751.751 0 00-.193-.137L1.885.965a.75.75 0 00-1.049.908L2.876 8 .837 14.128a.75.75 0 001.05.909L14.61 8.672a.75.75 0 00.192-1.203zM12.594 8l-9.76 4.883L4.207 8.75l3.703.002a.75.75 0 00.525-1.3.75.75 0 00-.525-.204H4.208L2.832 3.118 12.594 8z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }