var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'app-upload-area': true,
      'app-upload-area--disabled': _vm.disabled,
      'app-upload-area--wide': _vm.wide
    }
  }, [_c('div', {
    class: {
      'app-upload-area__area': true,
      'app-upload-area__area--file-in': _vm.draggedIn,
      'app-upload-area__self-interface': _vm.useSelfInterface
    },
    on: {
      "dragend": function ($event) {
        $event.preventDefault();
      },
      "dragenter": function ($event) {
        $event.preventDefault();
        return _vm.onDragEnter.apply(null, arguments);
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        return _vm.onDragLeave.apply(null, arguments);
      },
      "dragover": function ($event) {
        $event.preventDefault();
      },
      "dragstart": function ($event) {
        $event.preventDefault();
      },
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.addFiles.apply(null, arguments);
      },
      "click": function ($event) {
        _vm.useSelfInterface ? _vm.openDialog() : undefined;
      }
    }
  }, [_c('input', {
    ref: "$fileInput",
    staticClass: "app-upload-area__file-input",
    attrs: {
      "type": "file",
      "multiple": _vm.multiple,
      "name": _vm.name,
      "disabled": _vm.disabled
    },
    on: {
      "change": _vm.addFiles
    }
  }), _vm._v(" "), !_vm.useSelfInterface ? _c('app-button', {
    staticClass: "app-upload-area__button",
    attrs: {
      "flat": "",
      "size": "small",
      "disabled": _vm.disabled
    },
    on: {
      "on-click": _vm.openDialog
    }
  }, [_c('upload-icon', {
    staticClass: "app-upload-area__icon"
  }), _vm._v("\n            Загрузите " + _vm._s(_vm.filesCaption) + "\n        ")], 1) : _vm._e(), _vm._v(" "), _vm._t("icon"), _vm._v(" "), !_vm.useSelfInterface ? _c('div', {
    staticClass: "app-upload-area__caption"
  }, [_vm._v("\n            или перетяните " + _vm._s(_vm.multiple ? 'их' : 'его') + " в эту область\n        ")]) : _vm._e()], 2), _vm._v(" "), _c('div', {
    staticClass: "app-upload-area__errors",
    attrs: {
      "v-if": _vm.totalErrors.length
    }
  }, _vm._l(_vm.totalErrors, function (error) {
    return _c('div', {
      key: error,
      staticClass: "app-upload-area__error"
    }, [_c('error-icon', {
      staticClass: "app-upload-area__error-icon"
    }), _vm._v("\n            " + _vm._s(error) + "\n        ")], 1);
  }), 0), _vm._v(" "), _vm.$slots.hint || _vm.hint || _vm.defaultHint ? _c('div', {
    staticClass: "app-upload-area__hint"
  }, [_vm._t("hint", function () {
    return [_vm._v("\n            " + _vm._s(_vm.hint || _vm.defaultHint) + "\n        ")];
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }