var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-dropdown', _vm._g(_vm._b({
    ref: "$dropdown",
    class: {
      'app-dropdown': true,
      'app-dropdown--wide': _vm.wide
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (defaultScope) {
        return [_vm._t("default", null, null, defaultScope)];
      }
    }, {
      key: "content",
      fn: function (contentScope) {
        return [_c('div', {
          staticClass: "app-dropdown__content"
        }, [_vm._t("content", null, null, contentScope)], 2)];
      }
    }], null, true)
  }, 'base-dropdown', _vm.$props, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }