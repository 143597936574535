


















import { defineComponent } from '@nuxtjs/composition-api'

import { controlItems } from '@/data/emoji-list'

export default defineComponent({
    props: {
        value: {
            type: String,
            default: null
        }
    },
    setup: (_props, { emit }) => {
        const pickCategory = (title: string) => {
            emit('input', title)
            emit('pick-category', title)
        }

        return {
            pickCategory,
            controlItems
        }
    }
})
