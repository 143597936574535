import { render, staticRenderFns } from "./-ChatMessagesList.vue?vue&type=template&id=9e83059e&scoped=true&"
import script from "./-ChatMessagesList.vue?vue&type=script&lang=ts&"
export * from "./-ChatMessagesList.vue?vue&type=script&lang=ts&"
import style0 from "./-ChatMessagesList.vue?vue&type=style&index=0&id=9e83059e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e83059e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSpinner: require('/var/www/app/src/components/ui/AppSpinner.vue').default})
