import { ref } from '@nuxtjs/composition-api'

import ClockIcon from '@/assets/img/appEmoji/clock.svg'
import SmileIcon from '@/assets/img/appEmoji/smile.svg'
import CatIcon from '@/assets/img/appEmoji/cat.svg'
import FoodIcon from '@/assets/img/appEmoji/food.svg'
import BallIcon from '@/assets/img/appEmoji/ball.svg'
import BulbIcon from '@/assets/img/appEmoji/bulb.svg'

export const controlItems = [
    {
        icon: ClockIcon,
        title: 'Часто используемые',
        id: 'clock'
    },
    {
        icon: SmileIcon,
        title: 'Смайлы и люди',
        id: 'smile'
    },
    {
        icon: CatIcon,
        title: 'Животные и природа',
        id: 'cat'
    },
    {
        icon: FoodIcon,
        title: 'Еда и напитки',
        id: 'food'
    },
    {
        icon: BallIcon,
        title: 'Активность',
        id: 'ball'
    },
    {
        icon: BulbIcon,
        title: 'Предметы',
        id: 'bulb'
    }
]

export const emojiList = ref([
    {
        blockTitle: 'Часто используемые',
        emojis: []
    },
    {
        blockTitle: 'Смайлы и люди',
        emojis: [
            {
                emojiCode: '&#128512;',
                title: 'GRINNING FACE'
            },
            {
                emojiCode: '&#128513;',
                title: 'GRINNING FACE WITH SMILING EYES'
            },
            {
                emojiCode: '&#128514;',
                title: 'FACE WITH TEARS OF JOY'
            },
            {
                emojiCode: '&#128515;',
                title: 'SMILING FACE WITH OPEN MOUTH AND SMILING EYES'
            },
            {
                emojiCode: '&#128516;',
                title: 'SMILING FACE WITH OPEN MOUTH AND COLD SWEAT'
            },
            {
                emojiCode: '&#128517;',
                title: 'SMILING FACE WITH OPEN MOUTH AND TIGHTLY-CLOSED EYES'
            },
            {
                emojiCode: '&#128518;',
                title: ''
            },
            {
                emojiCode: '&#128519;',
                title: 'SMILING FACE WITH HALO'
            },
            {
                emojiCode: '&#128520;',
                title: 'SMILING FACE WITH HORNS'
            },
            {
                emojiCode: '&#128521;',
                title: 'WINKING FACE'
            },
            {
                emojiCode: '&#128522;',
                title: 'SMILING FACE WITH SMILING EYES'
            },
            {
                emojiCode: '&#128523;',
                title: 'FACE SAVOURING DELICIOUS FOOD'
            },
            {
                emojiCode: '&#128524;',
                title: 'RELIEVED FACE'
            },
            {
                emojiCode: '&#128525;',
                title: 'SMILING FACE WITH HEART-SHAPED EYES'
            },
            {
                emojiCode: '&#128526;',
                title: 'SMILING FACE WITH SUNGLASSES'
            },
            {
                emojiCode: '&#128527;',
                title: 'SMIRKING FACE'
            },
            {
                emojiCode: '&#128528;',
                title: 'NEUTRAL FACE'
            },
            {
                emojiCode: '&#128529;',
                title: 'EXPRESSIONLESS FACE'
            },
            {
                emojiCode: '&#128530;',
                title: 'UNAMUSED FACE'
            },
            {
                emojiCode: '&#128531;',
                title: 'FACE WITH COLD SWEAT'
            },
            {
                emojiCode: '&#128532;',
                title: 'PENSIVE FACE'
            },
            {
                emojiCode: '&#128533;',
                title: 'CONFUSED FACE'
            },
            {
                emojiCode: '&#128534;',
                title: 'CONFOUNDED FACE'
            },
            {
                emojiCode: '&#128535;',
                title: 'KISSING FACE'
            },
            {
                emojiCode: '&#128536;',
                title: 'FACE THROWING A KISS'
            },
            {
                emojiCode: '&#128537;',
                title: 'KISSING FACE WITH SMILING EYES'
            },
            {
                emojiCode: '&#128538;',
                title: 'KISSING FACE WITH CLOSED EYES'
            },
            {
                emojiCode: '&#128539;',
                title: 'FACE WITH STUCK-OUT TONGUE'
            },
            {
                emojiCode: '&#128540;',
                title: 'FACE WITH STUCK-OUT TONGUE AND WINKING EYE'
            },
            {
                emojiCode: '&#128541;',
                title: 'FACE WITH STUCK-OUT TONGUE AND TIGHTLY-CLOSED EYES'
            },
            {
                emojiCode: '&#128542;',
                title: 'DISAPPOINTED FACE'
            },
            {
                emojiCode: '&#128543;',
                title: 'WORRIED FACE'
            },
            {
                emojiCode: '&#128544;',
                title: 'ANGRY FACE'
            },
            {
                emojiCode: '&#128545;',
                title: 'POUTING FACE'
            },
            {
                emojiCode: '&#128546;',
                title: 'CRYING FACE'
            },
            {
                emojiCode: '&#9995;',
                title: 'Hay'
            },
            {
                emojiCode: '&#128075;',
                title: 'Hello'
            },
            {
                emojiCode: '&#128400;',
                title: 'Five thumbs'
            },
            {
                emojiCode: '&#9757;',
                title: 'Up'
            },
            {
                emojiCode: '&#128070;',
                title: 'Up'
            },
            {
                emojiCode: '&#128071;',
                title: 'Down'
            },
            {
                emojiCode: '&#128072;',
                title: 'Left'
            },
            {
                emojiCode: '&#128073;',
                title: 'Right'
            },
            {
                emojiCode: '&#128074;',
                title: ''
            },
            {
                emojiCode: '&#128077;',
                title: ''
            },
            {
                emojiCode: '&#128078;',
                title: ''
            },
            {
                emojiCode: '&#129307;',
                title: ''
            },
            {
                emojiCode: '&#129308;',
                title: ''
            },
            {
                emojiCode: '&#9996;',
                title: ''
            },
            {
                emojiCode: '&#128076;',
                title: ''
            },
            {
                emojiCode: '&#129295;',
                title: ''
            },
            {
                emojiCode: '&#9997;',
                title: ''
            },
            {
                emojiCode: '&#129309;',
                title: ''
            }
        ]
    },
    {
        blockTitle: 'Животные и природа',
        emojis: [
            {
                emojiCode: '&#128000;',
                title: 'Rat'
            },
            {
                emojiCode: '&#128001;',
                title: 'Mouse'
            },
            {
                emojiCode: '&#128002;',
                title: 'Ox'
            },
            {
                emojiCode: '&#128003;',
                title: 'Water buffalo'
            },
            {
                emojiCode: '&#128004;',
                title: 'Cow'
            },
            {
                emojiCode: '&#128005;',
                title: 'Tiger'
            },
            {
                emojiCode: '&#128006;',
                title: 'Leopard'
            },
            {
                emojiCode: '&#128007;',
                title: 'Rabbit'
            },
            {
                emojiCode: '&#128008;',
                title: 'Cat'
            },
            {
                emojiCode: '&#128009;',
                title: 'Dragon'
            },
            {
                emojiCode: '&#128010;',
                title: 'Crocodile'
            },
            {
                emojiCode: '&#128011;',
                title: 'Whale'
            },
            {
                emojiCode: '&#128012;',
                title: 'Snail'
            },
            {
                emojiCode: '&#128013;',
                title: 'Snake'
            },
            {
                emojiCode: '&#128014;',
                title: 'Horse'
            },
            {
                emojiCode: '&#128015;',
                title: 'Ram'
            },
            {
                emojiCode: '&#128016;',
                title: 'Goat'
            },
            {
                emojiCode: '&#128017;',
                title: 'Sheep'
            },
            {
                emojiCode: '&#128018;',
                title: 'Monkey'
            },
            {
                emojiCode: '&#128019;',
                title: 'Rooster'
            },
            {
                emojiCode: '&#128020;',
                title: 'Chicken'
            },
            {
                emojiCode: '&#128021;',
                title: 'Dog'
            },
            {
                emojiCode: '&#128022;',
                title: 'Pig'
            },
            {
                emojiCode: '&#128023;',
                title: 'Boar'
            },
            {
                emojiCode: '&#128024;',
                title: 'Elephant'
            },
            {
                emojiCode: '&#128025;',
                title: 'Fish'
            },
            {
                emojiCode: '&#128026;',
                title: 'Spiral Shell'
            },
            {
                emojiCode: '&#128027;',
                title: 'Bug'
            },
            {
                emojiCode: '&#128028;',
                title: 'Ant'
            },
            {
                emojiCode: '&#128029;',
                title: 'Honeybee'
            },
            {
                emojiCode: '&#128030;',
                title: 'Lady beetle'
            },
            {
                emojiCode: '&#128031;',
                title: 'Fish'
            },
            {
                emojiCode: '&#128032;',
                title: 'Tropical fish'
            },
            {
                emojiCode: '&#128033;',
                title: 'Blowfish'
            },
            {
                emojiCode: '&#128034;',
                title: 'Turtle'
            },
            {
                emojiCode: '&#128035;',
                title: 'Hatching chick'
            },
            {
                emojiCode: '&#128036;',
                title: 'Baby chick'
            },
            {
                emojiCode: '&#128037;',
                title: 'Front baby chick'
            },
            {
                emojiCode: '&#128038;',
                title: 'Bird'
            },
            {
                emojiCode: '&#128039;',
                title: 'Penguin'
            },
            {
                emojiCode: '&#128040;',
                title: 'Koala'
            },
            {
                emojiCode: '&#128041;',
                title: 'Poodle'
            },
            {
                emojiCode: '&#128042;',
                title: 'Dromedary'
            },
            {
                emojiCode: '&#128043;',
                title: 'Camel'
            },
            {
                emojiCode: '&#128044;',
                title: 'Dolphin'
            },
            {
                emojiCode: '&#128045;',
                title: 'Mouse face'
            },
            {
                emojiCode: '&#128046;',
                title: 'Cow face'
            },
            {
                emojiCode: '&#128047;',
                title: 'Tiger face'
            },
            {
                emojiCode: '&#128048;',
                title: 'Rabbit face'
            },
            {
                emojiCode: '&#128049;',
                title: 'Cat face'
            },
            {
                emojiCode: '&#128050;',
                title: 'Dragon face'
            },
            {
                emojiCode: '&#128051;',
                title: 'Spouting whale'
            },
            {
                emojiCode: '&#128052;',
                title: 'Horse face'
            },
            {
                emojiCode: '&#128053;',
                title: 'Monkey face'
            },
            {
                emojiCode: '&#128054;',
                title: 'Dog face'
            },
            {
                emojiCode: '&#128055;',
                title: 'Pig face'
            },
            {
                emojiCode: '&#128056;',
                title: 'Frog face'
            },
            {
                emojiCode: '&#128057;',
                title: 'Hamster face'
            },
            {
                emojiCode: '&#128058;',
                title: 'Wolf face'
            },
            {
                emojiCode: '&#128059;',
                title: 'Bear face'
            },
            {
                emojiCode: '&#128060;',
                title: 'Panda face'
            },
            {
                emojiCode: '&#128061;',
                title: 'Pig nose'
            },
            {
                emojiCode: '&#128062;',
                title: 'Paw prints'
            }
        ]
    },
    {
        blockTitle: 'Еда и напитки',
        emojis: [
            {
                emojiCode: '&#9749;',
                title: 'Hot beverage'
            },{
                emojiCode: '&#127861;',
                title: 'Teacup'
            },
            {
                emojiCode: '&#127862;',
                title: 'Sake'
            },
            {
                emojiCode: '&#127863;',
                title: 'Red wine'
            },
            {
                emojiCode: '&#127864;',
                title: 'Coctail'
            },
            {
                emojiCode: '&#127865;',
                title: 'Tropical'
            },
            {
                emojiCode: '&#127866;',
                title: 'Beer mug'
            },
            {
                emojiCode: '&#127867;',
                title: 'Beer mugs'
            },
            {
                emojiCode: '&#127868;',
                title: 'Baby bottle'
            },
            {
                emojiCode: '&#127870;',
                title: 'Sparkling wine'
            },
            {
                emojiCode: '&#127994;',
                title: 'Amphora'
            },{
                emojiCode: '&#129346;',
                title: 'Clinking glass'
            },
            {
                emojiCode: '&#129347;',
                title: 'Tumbler glass'
            },
            {
                emojiCode: '&#129371;',
                title: 'Milk glass'
            },
            {
                emojiCode: '&#129380;',
                title: 'Cup with straw'
            },
            {
                emojiCode: '&#129475;',
                title: 'Beverage box'
            },
            {
                emojiCode: '&#129481;',
                title: 'Mate'
            },
            {
                emojiCode: '&#129482;',
                title: 'Ice'
            },
            {
                emojiCode: '&#127789;',
                title: 'Hot dog'
            },{
                emojiCode: '&#127790;',
                title: 'Taco'
            },
            {
                emojiCode: '&#127791;',
                title: 'Burrito'
            },
            {
                emojiCode: '&#127828;',
                title: 'Hamburger'
            },
            {
                emojiCode: '&#127829;',
                title: 'Pizza'
            },
            {
                emojiCode: '&#127830;',
                title: 'Meat on bone'
            },
            {
                emojiCode: '&#127831;',
                title: 'Poultry leg'
            },
            {
                emojiCode: '&#127843;',
                title: 'Sushi'
            },
            {
                emojiCode: '&#127844;',
                title: 'Fried shrimp'
            },
            {
                emojiCode: '&#127845;',
                title: 'Fish cake'
            },
            {
                emojiCode: '&#127846;',
                title: 'Soft ice'
            },{
                emojiCode: '&#127849;',
                title: 'Doughnut'
            }
        ]
    },
    {
        blockTitle: 'Активность',
        emojis: [
            {
                emojiCode: '&#9917;',
                title: 'Soccer ball'
            },
            {
                emojiCode: '&#9918;',
                title: 'Baseball'
            },
            {
                emojiCode: '&#127936;',
                title: 'Basketball'
            },
            {
                emojiCode: '&#127939;',
                title: 'Runner'
            },
            {
                emojiCode: '&#127941;',
                title: 'Sports medal'
            },
            {
                emojiCode: '&#127918;',
                title: 'Video game'
            },
            {
                emojiCode: '&#127919;',
                title: 'Direct hit'
            },
            {
                emojiCode: '&#127921;',
                title: 'Billiards'
            },
            {
                emojiCode: '&#127923;',
                title: 'Bowling'
            },
            {
                emojiCode: '&#129513;',
                title: 'Puzzle'
            },
            {
                emojiCode: '&#129351;',
                title: 'First place medal'
            },
            {
                emojiCode: '&#129352;',
                title: 'Second place medal'
            },
            {
                emojiCode: '&#129353;',
                title: 'Third place medal'
            },
        ]
    },
    {
        blockTitle: 'Предметы',
        emojis: [
            {
                emojiCode: '&#128293;',
                title: 'Fire'
            },
            {
                emojiCode: '&#128279;',
                title: 'Link'
            },
            {
                emojiCode: '&#128276;',
                title: 'Bell'
            },
            {
                emojiCode: '&#128173;',
                title: 'Thought Ballon'
            },
            {
                emojiCode: '&#128172;',
                title: 'Speech Ballon'
            },
            {
                emojiCode: '&#128169;',
                title: 'Pile of poo'
            },
            {
                emojiCode: '&#128165;',
                title: 'Collision'
            },
            {
                emojiCode: '&#128164;',
                title: 'Sleeping'
            },
            {
                emojiCode: '&#128161;',
                title: 'Light bulb'
            },
            {
                emojiCode: '&#128296;',
                title: 'Hammer'
            },
            {
                emojiCode: '&#128295;',
                title: 'Wrench'
            },
            {
                emojiCode: '&#128275;',
                title: 'Open lock'
            },
            {
                emojiCode: '&#128274;',
                title: 'Lock'
            },
            {
                emojiCode: '&#128273;',
                title: 'Key'
            },
            {
                emojiCode: '&#128270;',
                title: 'Right magnifying glass'
            },
            {
                emojiCode: '&#128269;',
                title: 'Left magnifying glass'
            },
            {
                emojiCode: '&#128179;',
                title: 'Credit card'
            },
            {
                emojiCode: '&#128178;',
                title: 'Dollar sign'
            },
            {
                emojiCode: '&#128177;',
                title: 'Currency exchange'
            },
            {
                emojiCode: '&#128176;',
                title: 'Money bag'
            }
        ]
    }
])