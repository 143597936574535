




































import {
    defineComponent,
    ref,
    watch
} from '@nuxtjs/composition-api';

export default defineComponent({
    props: {
        emojiList: {
            type: Array,
            default: []
        },
        isSearch: {
            type: Boolean,
            default: false
        },
        currentCategory: {
            type: String,
            default: ''
        }
    },
    setup: (props, { emit }) => {
        const $block = ref<HTMLElement[] | null>(null)
        const pickEmoji = (code: string) => {
            emit('pick-emoji', code)
        }

        const findCategory = (title: string) => {
            const block = $block.value?.find(category => category.innerText === title)
            block?.scrollIntoView({ behavior: 'smooth' })
        }

        watch(
            () => props.currentCategory,
            (value) => findCategory(value)
        )

        return {
            $block,
            pickEmoji,
            findCategory
        }
    }

})
