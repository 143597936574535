var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "the-mobile-catalog"
  }, _vm._l(_vm.catalogItems, function (item) {
    return _c('catalog-item', {
      key: item.alias,
      staticClass: "the-mobile-catalog__catalog-item",
      attrs: {
        "item": item,
        "aside": ""
      },
      on: {
        "on-click": _vm.onClick
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }