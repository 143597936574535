var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "the-catalog"
  }, [_c('catalog-profile', {
    staticClass: "the-catalog__profile",
    attrs: {
      "profile-link": _vm.profileLink
    }
  }), _vm._v(" "), _c('app-divider', {
    staticClass: "the-catalog__divider"
  }), _vm._v(" "), _c('div', {
    staticClass: "the-catalog__catalog-items"
  }, _vm._l(_vm.catalogItems, function (item) {
    return _c('catalog-item', {
      key: item.alias,
      staticClass: "the-catalog__catalog-item",
      attrs: {
        "item": item
      }
    });
  }), 1), _vm._v(" "), _c('app-divider', {
    staticClass: "the-catalog__divider"
  }), _vm._v(" "), _c('catalog-logout-button', {
    staticClass: "the-catalog__logout",
    attrs: {
      "shadow": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }