var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M3.4 13.71c0 3.978 4.116 9.29 7.204 9.29.306 0 .611-.055.917-.167a3.377 3.377 0 012.448 0c.278.112.584.167.918.167 3.115 0 7.203-5.284 7.203-9.29 0-4.143-2.447-7.509-5.451-7.509-.779 0-1.502.223-2.197.64-.362.222-.751.361-1.14.417.083-1.029.5-3.866 2.864-4.812a.376.376 0 00.195-.445.376.376 0 00-.445-.194c-1.39.556-2.42 1.752-2.948 3.449 0 .027-.028.055-.028.083A6.307 6.307 0 006.932 1c-.194 0-.334.167-.334.362 0 1.919.89 3.671 2.253 4.811h-.027C5.848 6.23 3.4 9.594 3.4 13.711zM7.322 1.724a5.608 5.608 0 015.256 5.257c-2.809-.14-5.09-2.42-5.256-5.257zm3.365 5.73a3.99 3.99 0 002.225.584h.139a3.972 3.972 0 001.752-.584 3.419 3.419 0 011.836-.529c2.614 0 4.756 3.06 4.756 6.814 0 3.894-4.005 8.595-6.536 8.595-.223 0-.445-.028-.64-.112a3.854 3.854 0 00-1.474-.278c-.5 0-1.001.084-1.474.278a2.434 2.434 0 01-.668.084c-2.53 0-6.535-4.7-6.535-8.594 0-3.755 2.141-6.815 4.756-6.815.64 0 1.279.195 1.863.557z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }