var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-emoji"
  }, [_c('emoji-icon', {
    staticClass: "app-emoji__icon",
    on: {
      "click": function ($event) {
        return _vm.openEmojiPicker($event);
      }
    }
  }), _vm._v(" "), _c('emoji-body-area', {
    ref: "$emojiPicker",
    on: {
      "pick-emoji": function ($event) {
        return _vm.$emit('pick-emoji', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }