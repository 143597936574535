var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat-header"
  }, [_c('div', {
    staticClass: "chat-header__info"
  }, [_c('chat-icon', {
    staticClass: "chat-header__icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "chat-header__text-info"
  }, [_c('div', {
    staticClass: "chat-header__title"
  }, [_vm._v("\n                " + _vm._s(_vm.title) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "chat-header__description"
  }, [_vm._v("\n                " + _vm._s(_vm.description) + "\n            ")])]), _vm._v(" "), _vm.hint ? _c('app-hint', {
    staticClass: "catalog-profile__id-hint",
    attrs: {
      "hoverable": "",
      "placement": "right"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.hint) + "\n        ")]) : _vm._e()], 1), _vm._v(" "), _vm.closable ? _c('div', {
    staticClass: "chat-header__close",
    on: {
      "click": function ($event) {
        return _vm.$emit('on-close');
      }
    }
  }, [_c('close-icon', {
    staticClass: "chat-header__close-icon"
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }