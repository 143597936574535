import { render, staticRenderFns } from "./partner.vue?vue&type=template&id=3264ec4c&"
import script from "./partner.vue?vue&type=script&lang=ts&"
export * from "./partner.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheMobileCatalog: require('/var/www/app/src/components/partner/TheMobileCatalog.vue').default,TheMobileProfile: require('/var/www/app/src/components/partner/TheMobileProfile.vue').default,TheCatalog: require('/var/www/app/src/components/partner/TheCatalog/index.vue').default,PartnerChatModal: require('/var/www/app/src/components/partner/PartnerChatModal.vue').default})
