var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'app-file': true,
      'app-file--disabled': _vm.disabled,
      'app-file--error': _vm.error
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('div', {
    staticClass: "app-file__info"
  }, [_c('div', {
    staticClass: "app-file__file-name-wrapper"
  }, [_c('div', {
    staticClass: "app-file__icon-wrapper"
  }, [_c(_vm.fileIcon, {
    tag: "component",
    class: ['app-file__icon', ("app-file__icon--" + (_vm.fileIcon))]
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "app-file__file-name"
  }, [_vm._v("\n                " + _vm._s(_vm.name) + "\n            ")])]), _vm._v(" "), !_vm.disabled ? _c('div', {
    staticClass: "app-file__icon-wrapper app-file__icon-wrapper--hoverable",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.action.apply(null, arguments);
      }
    }
  }, [_c(_vm.actionIcon, {
    tag: "component",
    class: ['app-file__icon', ("app-file__icon--" + (_vm.actionIcon))]
  })], 1) : _vm._e()]), _vm._v(" "), !_vm.isUploaded ? [_c('div', {
    class: {
      'app-file__progress-bar': true,
      'app-file__progress-bar--error': _vm.error
    }
  }, [_c('div', {
    class: {
      'app-file__progress-size': true,
      'app-file__progress-size--error': _vm.error
    },
    style: {
      width: ((_vm.progressSize) + "%")
    }
  })]), _vm._v(" "), _c('div', {
    class: {
      'app-file__status': true,
      'app-file__status--error': _vm.error
    }
  }, [_c('div', {
    staticClass: "app-file__sizes"
  }, [_vm._v("\n                " + _vm._s(_vm.formattedUploadSize) + " / " + _vm._s(_vm.formattedSize) + "\n            ")]), _vm._v(" "), _c('div', {
    class: {
      'app-file__status-caption': true,
      'app-file__status-caption--error': _vm.error
    }
  }, [_vm._v("\n                " + _vm._s(_vm.error ? 'Ошибка загрузки' : ("Загружено " + (_vm.progressSize.toFixed(0)) + "%")) + "\n            ")])])] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }