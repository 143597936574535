import { render, staticRenderFns } from "./index.vue?vue&type=template&id=70d61b8b&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=70d61b8b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70d61b8b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppUploadArea: require('/var/www/app/src/components/ui/AppUpload/AppUploadArea.vue').default,AppFile: require('/var/www/app/src/components/ui/AppUpload/AppFile.vue').default})
