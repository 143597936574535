export const allMimeTypes = [
    'application/envoy',
    'application/epub+zip',
    'application/fractals',
    'application/futuresplash',
    'application/hta',
    'application/internet-property-stream',
    'application/java-archive',
    'application/javascript',
    'application/json',
    'application/mac-binhex40',
    'application/msword',
    'application/octet-stream',
    'application/oda',
    'application/ogg',
    'application/olescript',
    'application/pdf',
    'application/pics-rules',
    'application/pkcs10',
    'application/pkix-crl',
    'application/postscript',
    'application/rtf',
    'application/set-payment-initiation',
    'application/set-registration-initiation',
    'application/vnd.amazon.ebook',
    'application/vnd.apple.installer+xml',
    'application/vnd.mozilla.xul+xml',
    'application/vnd.ms-excel',
    'application/vnd.ms-outlook',
    'application/vnd.ms-pkicertstore',
    'application/vnd.ms-pkiseccat',
    'application/vnd.ms-pkistl',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-project',
    'application/vnd.ms-works',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.visio',
    'application/winhlp',
    'application/x-7z-compressed',
    'application/x-abiword',
    'application/x-bcpio',
    'application/x-bzip',
    'application/x-bzip2',
    'application/x-cdf',
    'application/x-compress',
    'application/x-compressed',
    'application/x-cpio',
    'application/x-csh',
    'application/x-director',
    'application/x-dvi',
    'application/x-gtar',
    'application/x-gzip',
    'application/x-hdf',
    'application/x-internet-signup',
    'application/x-iphone',
    'application/x-javascript',
    'application/x-latex',
    'application/x-msaccess',
    'application/x-mscardfile',
    'application/x-msclip',
    'application/x-msdownload',
    'application/x-msmediaview',
    'application/x-msmetafile',
    'application/x-msmoney',
    'application/x-mspublisher',
    'application/x-msschedule',
    'application/x-msterminal',
    'application/x-mswrite',
    'application/x-netcdf',
    'application/x-perfmon',
    'application/x-pkcs12',
    'application/x-pkcs7-certificates',
    'application/x-pkcs7-certreqresp',
    'application/x-pkcs7-mime',
    'application/x-pkcs7-signature',
    'application/x-rar-compressed',
    'application/x-sh',
    'application/x-shar',
    'application/x-shockwave-flash',
    'application/x-stuffit',
    'application/x-sv4cpio',
    'application/x-sv4crc',
    'application/x-tar',
    'application/x-tcl',
    'application/x-tex',
    'application/x-texinfo',
    'application/x-troff-man',
    'application/x-troff-me',
    'application/x-troff-ms',
    'application/x-troff',
    'application/x-ustar',
    'application/x-wais-source',
    'application/x-x509-ca-cert',
    'application/xhtml+xml',
    'application/xml',
    'application/ynd.ms-pkipko',
    'application/zip',
    'audio/aac',
    'audio/basic',
    'audio/mid',
    'audio/midi',
    'audio/mpeg',
    'audio/ogg',
    'audio/webm',
    'audio/x-aiff',
    'audio/x-mpegurl',
    'audio/x-pn-realaudio',
    'audio/x-wav',
    'font/ttf',
    'font/woff',
    'font/woff2',
    'image/bmp',
    'image/cis-cod',
    'image/gif',
    'image/ief',
    'image/jpeg',
    'image/pipeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
    'image/x-cmu-raster',
    'image/x-cmx',
    'image/x-icon',
    'image/x-portable-anymap',
    'image/x-portable-bitmap',
    'image/x-portable-graymap',
    'image/x-portable-pixmap',
    'image/x-rgb',
    'image/x-xbitmap',
    'image/x-xpixmap',
    'image/x-xwindowdump',
    'message/rfc822',
    'text/calendar',
    'text/css',
    'text/csv',
    'text/h323',
    'text/html',
    'text/iuls',
    'text/plain',
    'text/richtext',
    'text/scriptlet',
    'text/tab-separated-values',
    'text/webviewhtml',
    'text/x-component',
    'text/x-setext',
    'text/x-vcard',
    'video/3gpp',
    'video/3gpp2',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/quicktime',
    'video/webm',
    'video/x-la-asf',
    'video/x-ms-asf',
    'video/x-msvideo',
    'video/x-sgi-movie',
    'x-world/x-vrml'
] as const

export type MIMEType = typeof allMimeTypes[number]

export type FileExtension = { [key in MIMEType]: string[] }

export const fileExtensions: FileExtension = {
    'application/envoy': [ '.evy' ],
    'application/epub+zip': [ '.epub' ],
    'application/fractals': [ '.fif' ],
    'application/futuresplash': [ '.spl' ],
    'application/hta': [ '.hta' ],
    'application/internet-property-stream': [ '.acx' ],
    'application/java-archive': [ '.jar' ],
    'application/javascript': [ '.js' ],
    'application/json': [ '.json' ],
    'application/mac-binhex40': [ '.hqx' ],
    'application/msword': [ '.doc', '.dot', '.doc' ],
    'application/octet-stream': [ '.saf', '.ini', '.dst', '.exe', '.dll' ],
    'application/oda': [ '.oda' ],
    'application/ogg': [ '.ogx' ],
    'application/olescript': [ '.axs' ],
    'application/pdf': [ '.pdf', '.pdf' ],
    'application/pics-rules': [ '.prf' ],
    'application/pkcs10': [ '.p10' ],
    'application/pkix-crl': [ '.crl' ],
    'application/postscript': [ '.ai', '.eps', '.ps' ],
    'application/rtf': [ '.rtf', '.rtf' ],
    'application/set-payment-initiation': [ '.setpay' ],
    'application/set-registration-initiation': [ '.setreg' ],
    'application/vnd.amazon.ebook': [ '.azw' ],
    'application/vnd.apple.installer+xml': [ '.mpkg' ],
    'application/vnd.mozilla.xul+xml': [ '.xul' ],
    'application/vnd.ms-excel': [ '.xla', '.xlc', '.xlm', '.xls', '.xlt', '.xlw' ],
    'application/vnd.ms-outlook': [ '.msg' ],
    'application/vnd.ms-pkicertstore': [ '.sst' ],
    'application/vnd.ms-pkiseccat': [ '.cat' ],
    'application/vnd.ms-pkistl': [ '.stl' ],
    'application/vnd.ms-powerpoint': [ '.pot', '.pps', '.ppt', '.ppt' ],
    'application/vnd.ms-project': [ '.mpp' ],
    'application/vnd.ms-works': [ '.wcm', '.wdb', '.wks', '.wps' ],
    'application/vnd.oasis.opendocument.presentation': [ '.odp' ],
    'application/vnd.oasis.opendocument.spreadsheet': [ '.ods' ],
    'application/vnd.oasis.opendocument.text': [ '.odt' ],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [ '.xlsx' ],
    'application/vnd.visio': [ '.vsd' ],
    'application/winhlp': [ '.hlp' ],
    'application/x-7z-compressed': [ '.7z' ],
    'application/x-abiword': [ '.abw' ],
    'application/x-bcpio': [ '.bcpio' ],
    'application/x-bzip': [ '.bz' ],
    'application/x-bzip2': [ '.bz2' ],
    'application/x-cdf': [ '.cdf' ],
    'application/x-compress': [ '.z' ],
    'application/x-compressed': [ '.tgz' ],
    'application/x-cpio': [ '.cpio' ],
    'application/x-csh': [ '.csh', '.csh' ],
    'application/x-director': [ '.dcr', '.dir', '.dxr' ],
    'application/x-dvi': [ '.dvi' ],
    'application/x-gtar': [ '.gtar' ],
    'application/x-gzip': [ '.gz' ],
    'application/x-hdf': [ '.hdf' ],
    'application/x-internet-signup': [ '.ins', '.isp' ],
    'application/x-iphone': [ '.iii' ],
    'application/x-javascript': [ '.js' ],
    'application/x-latex': [ '.latex' ],
    'application/x-msaccess': [ '.mdb' ],
    'application/x-mscardfile': [ '.crd' ],
    'application/x-msclip': [ '.clp' ],
    'application/x-msdownload': [ '.dll' ],
    'application/x-msmediaview': [ '.m13', '.m14', '.mvb' ],
    'application/x-msmetafile': [ '.wmf' ],
    'application/x-msmoney': [ '.mny' ],
    'application/x-mspublisher': [ '.pub' ],
    'application/x-msschedule': [ '.scd' ],
    'application/x-msterminal': [ '.trm' ],
    'application/x-mswrite': [ '.wri' ],
    'application/x-netcdf': [ '.cdf', '.nc' ],
    'application/x-perfmon': [ '.pma', '.pmc', '.pml', '.pmr', '.pmw' ],
    'application/x-pkcs12': [ '.p12', '.pfx' ],
    'application/x-pkcs7-certificates': [ '.p7b', '.spc' ],
    'application/x-pkcs7-certreqresp': [ '.p7r' ],
    'application/x-pkcs7-mime': [ '.p7c', '.p7m' ],
    'application/x-pkcs7-signature': [ '.p7s' ],
    'application/x-rar-compressed': [ '.rar' ],
    'application/x-sh': [ '.sh', '.sh' ],
    'application/x-shar': [ '.shar' ],
    'application/x-shockwave-flash': [ '.swf', '.swf' ],
    'application/x-stuffit': [ '.sit' ],
    'application/x-sv4cpio': [ '.sv4cpio' ],
    'application/x-sv4crc': [ '.sv4crc' ],
    'application/x-tar': [ '.tar', '.tar' ],
    'application/x-tcl': [ '.tcl' ],
    'application/x-tex': [ '.tex' ],
    'application/x-texinfo': [ '.texi', '.texinfo' ],
    'application/x-troff-man': [ '.man' ],
    'application/x-troff-me': [ '.me' ],
    'application/x-troff-ms': [ '.ms' ],
    'application/x-troff': [ '.roff', '.t', '.tr' ],
    'application/x-ustar': [ '.ustar' ],
    'application/x-wais-source': [ '.src' ],
    'application/x-x509-ca-cert': [ '.cer', '.crt', '.der' ],
    'application/xhtml+xml': [ '.xhtml' ],
    'application/xml': [ '.xml' ],
    'application/ynd.ms-pkipko': [ '.pko' ],
    'application/zip': [ '.zip', '.zip' ],
    'audio/aac': [ '.aac' ],
    'audio/basic': [ '.au', '.snd' ],
    'audio/mid': [ '.mid', '.rmi' ],
    'audio/midi': [ '.midi' ],
    'audio/mpeg': [ '.mp3' ],
    'audio/ogg': [ '.oga' ],
    'audio/webm': [ '.weba' ],
    'audio/x-aiff': [ '.aif', '.aifc', '.aiff' ],
    'audio/x-mpegurl': [ '.m3u' ],
    'audio/x-pn-realaudio': [ '.ra', '.ram' ],
    'audio/x-wav': [ '.wav', '.wav' ],
    'font/ttf': [ '.ttf' ],
    'font/woff': [ '.woff' ],
    'font/woff2': [ '.woff2' ],
    'image/bmp': [ '.bmp' ],
    'image/cis-cod': [ '.cod' ],
    'image/gif': [ '.gif', '.gif' ],
    'image/ief': [ '.ief' ],
    'image/jpeg': [ '.jpeg', '.jpg', '.jpe' ],
    'image/pipeg': [ '.jfif' ],
    'image/png': [ '.png' ],
    'image/svg+xml': [ '.svg', '.svg' ],
    'image/tiff': [ '.tif', '.tiff', '.tiff' ],
    'image/webp': [ '.webp' ],
    'image/x-cmu-raster': [ '.ras' ],
    'image/x-cmx': [ '.cmx' ],
    'image/x-icon': [ '.ico', '.ico' ],
    'image/x-portable-anymap': [ '.pnm' ],
    'image/x-portable-bitmap': [ '.pbm' ],
    'image/x-portable-graymap': [ '.pgm' ],
    'image/x-portable-pixmap': [ '.ppm' ],
    'image/x-rgb': [ '.rgb' ],
    'image/x-xbitmap': [ '.xbm' ],
    'image/x-xpixmap': [ '.xpm' ],
    'image/x-xwindowdump': [ '.xwd' ],
    'message/rfc822': [ '.mht', '.mhtml', '.nws' ],
    'text/calendar': [ '.ics' ],
    'text/css': [ '.css', '.css' ],
    'text/csv': [ '.csv', '.csv' ],
    'text/h323': [ '.323' ],
    'text/html': [ '.htm', '.html', '.stm', '.html' ],
    'text/iuls': [ '.uls' ],
    'text/plain': [ '.bas', '.c', '.h', '.txt' ],
    'text/richtext': [ '.rtx' ],
    'text/scriptlet': [ '.sct' ],
    'text/tab-separated-values': [ '.tsv' ],
    'text/webviewhtml': [ '.htt' ],
    'text/x-component': [ '.htc' ],
    'text/x-setext': [ '.etx' ],
    'text/x-vcard': [ '.vcf' ],
    'video/3gpp': [ '.3gp' ],
    'video/3gpp2': [ '.3g2' ],
    'video/mp4': [ '.mp4' ],
    'video/mpeg': [ '.mp2', '.mpa', '.mpe', '.mpeg', '.mpg', '.mpv2', '.mpeg' ],
    'video/ogg': [ '.ogv' ],
    'video/quicktime': [ '.mov', '.qt' ],
    'video/webm': [ '.webm' ],
    'video/x-la-asf': [ '.lsf', '.lsx' ],
    'video/x-ms-asf': [ '.asf', '.asr', '.asx' ],
    'video/x-msvideo': [ '.avi', '.avi' ],
    'video/x-sgi-movie': [ '.movie' ],
    'x-world/x-vrml': [ '.flr', '.vrml', '.wrl', '.wrz', '.xaf', '.xof' ]
}
