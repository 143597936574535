var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 26 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M10.194 11.643a.901.901 0 11-1.802 0 .901.901 0 011.802 0zm5.713-.9a.901.901 0 100 1.802.901.901 0 000-1.803zm8.685 5.326a.41.41 0 01-.48.322l-2.46-.488c-1.246 2.78-4.832 4.794-9.052 4.794s-7.806-2.014-9.053-4.794l-2.458.488a.41.41 0 01-.16-.803l2.336-.463a5.153 5.153 0 01-.19-1.379c0-.252.026-.503.063-.753L.93 12.555a.409.409 0 11.159-.803l2.227.442c.057-.18.122-.36.199-.539a6.463 6.463 0 01.519-1.843c.081-.182.12-.395.116-.631l-.083-4.095a1.053 1.053 0 01.422-.87c.274-.207.621-.27.95-.169l4.092 1.244a10.889 10.889 0 016.138 0l4.091-1.244c.33-.1.676-.038.951.17.275.208.428.524.422.869L21.05 9.18c-.004.236.034.449.116.632.268.599.442 1.218.52 1.842.076.178.141.358.198.54l2.227-.443a.409.409 0 11.16.802l-2.21.44c.038.249.063.5.063.752 0 .472-.065.933-.189 1.379l2.335.463a.41.41 0 01.322.481zm-3.286-2.323c0-.199-.019-.396-.047-.593l-1.776.352a.41.41 0 01-.16-.803l1.753-.347a4.82 4.82 0 00-.164-.426.403.403 0 01-.032-.12 5.642 5.642 0 00-.461-1.663 2.281 2.281 0 01-.187-.981l.082-4.095a.24.24 0 00-.097-.2.24.24 0 00-.219-.04l-4.21 1.28a.406.406 0 01-.24-.001 10.064 10.064 0 00-5.896 0 .41.41 0 01-.24 0L5.202 4.83a.243.243 0 00-.22.039.24.24 0 00-.097.2l.083 4.095c.007.358-.056.688-.187.982a5.636 5.636 0 00-.46 1.663.412.412 0 01-.033.12c-.062.14-.116.283-.164.426l1.752.348a.41.41 0 01-.16.802l-1.776-.353a4.301 4.301 0 00-.046.594c0 .418.06.825.173 1.22l1.65-.328a.41.41 0 01.159.803l-1.507.299c1.177 2.405 4.421 4.139 8.231 4.139 3.81 0 7.054-1.734 8.231-4.14l-1.507-.299a.41.41 0 01.16-.802l1.648.327a4.4 4.4 0 00.174-1.219z"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "12.6",
      "cy": "14",
      "rx": "2",
      "ry": "1"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }