/* eslint-disable no-useless-constructor */

import { ref, reactive, Ref } from '@nuxtjs/composition-api'
import { Merge } from './joins'

export interface Scrollable<Item, Cursor> {
    forwardCursor?: Cursor
    items?: Item[]
}

export type JoinMethod = 'Push' | 'Unshift'

export type CallbackResult<Item, Cursor> = Merge<
    Scrollable<Item, Cursor>,
    { data: Scrollable<Item, Cursor> }
>

export type ScrollableCallback<Item, Cursor> = (
    fromCursor?: Cursor
) => Promise<CallbackResult<Item, Cursor>>

type AttachedElement = HTMLElement | Element | Window

export interface Options<Item, Cursor> {
    forwardCursor?: Cursor
    items?: Item[]
    more?: boolean
    fetch?: boolean
    joinMethod?: JoinMethod
    reversedScroll?: boolean
    autoScroll?: boolean
}

export const useScrollableCollection = <Item, Cursor>(
    callback: ScrollableCallback<Item, Cursor>,
    options?: Options<Item, Cursor>
) => {
    const {
        forwardCursor: _forwardCursor = undefined,
        items: _items = [],
        more: _more = false,
        fetch: _fetch = false,
        joinMethod = 'Push',
        reversedScroll = false,
        autoScroll = false
    } = options || {}

    const forwardCursor = ref(_forwardCursor) as Ref<Cursor | undefined>
    const items = ref(_items) as Ref<Item[]>
    const isLoading = reactive({
        more: _more,
        fetch: _fetch
    })

    const loadMore = async () => {
        if (!forwardCursor.value || isLoading.more) {
            return undefined
        }

        isLoading.more = true

        const response = await callback(forwardCursor.value)

        forwardCursor.value = response.data?.forwardCursor || response.forwardCursor
        const responseItems = response.data?.items || response.items

        if (!responseItems?.length) {
            isLoading.more = false

            return undefined
        }

        if (joinMethod === 'Push') {
            items.value = items.value.concat(responseItems)
        }

        if (joinMethod === 'Unshift') {
            items.value = responseItems.concat(items.value)
        }

        isLoading.more = false
    }

    let attachedElement: AttachedElement | null = null

    const onScroll = async (event?: { target: Event['target'] }) => {
        const element = event?.target as AttachedElement | null

        if (
            isLoading.more
            || isLoading.fetch
            || !element
            || !forwardCursor.value
        ) {
            return undefined
        }

        const actualElement = (element instanceof Window
            ? element.document.documentElement
            : element) as HTMLElement
        const { scrollHeight, offsetHeight, scrollTop } = actualElement

        if (
            (reversedScroll && scrollTop > offsetHeight)
            || (!reversedScroll && scrollTop + offsetHeight < scrollHeight - offsetHeight)
        ) {
            return undefined
        }

        await loadMore()

        if (autoScroll) {
            onScroll(event)
        }
    }

    const fetch = async () => {
        isLoading.fetch = true
        items.value = []
        forwardCursor.value = undefined

        const response = await callback(forwardCursor.value)

        items.value = response.data?.items || response.items || []
        forwardCursor.value = response.data?.forwardCursor || response.forwardCursor
        isLoading.fetch = false

        if (autoScroll) {
            onScroll({ target: attachedElement })
        }
    }

    const detach = () => attachedElement?.removeEventListener('scroll', onScroll)
    const attach = (element?: AttachedElement | null | undefined) => {
        if (attachedElement) detach()

        attachedElement = element || window

        attachedElement.addEventListener('scroll', onScroll)

        if (autoScroll) {
            onScroll({ target: attachedElement })
        }
    }

    return {
        items,
        isLoading,
        forwardCursor,
        loadMore,
        fetch,
        attach,
        detach,
        onScroll
    }
}
