var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-upload"
  }, [!_vm.hideArea ? _c('app-upload-area', {
    staticClass: "app-upload__area",
    attrs: {
      "hint": _vm.hint,
      "multiple": _vm.multiple,
      "allowed-types": _vm.allowedTypes,
      "max-size": _vm.maxSize,
      "errors": _vm.errors,
      "disabled": _vm.disabled,
      "name": _vm.name,
      "use-self-interface": _vm.useSelfInterface
    },
    on: {
      "input": _vm.addFiles
    }
  }, [_vm.$slots.hint || _vm.hint ? _vm._t("hint", function () {
    return [_vm._v("\n            " + _vm._s(_vm.hint) + "\n        ")];
  }, {
    "slot": "hint",
    "hint": _vm.hint
  }) : _vm._e(), _vm._v(" "), _vm._t("icon", null, {
    "slot": "icon"
  })], 2) : _vm._e(), _vm._v(" "), _vm.files.length && !_vm.useSelfInterface ? _c('div', {
    staticClass: "app-upload__files-list"
  }, [_vm._l(_vm.files, function (fileInfo, index) {
    return _c('app-file', {
      key: fileInfo.id,
      staticClass: "app-upload__file",
      attrs: {
        "name": fileInfo.file.name,
        "size": fileInfo.size,
        "uploaded-size": fileInfo.uploadedSize,
        "disabled": _vm.disabled,
        "error": Boolean(fileInfo.error)
      },
      on: {
        "on-cancel": function ($event) {
          return _vm.remove(index);
        },
        "on-retry": function ($event) {
          return _vm.retry(index);
        },
        "on-click": function ($event) {
          return _vm.onFileClick(fileInfo);
        }
      }
    });
  }), _vm._v(" "), _c('div', {
    staticClass: "app-upload__files-list-hint"
  }, [_vm._v("\n            " + _vm._s(_vm.hint) + "\n        ")])], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }