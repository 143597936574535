



























import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import AppChat from '@/components/ui/AppChat/index.vue'
import BaseModal from '@/components/ui/BaseModal.vue'

export default defineComponent({
    props: {
        ...AppChat.props,
        maxWidth: {
            type: Number,
            default: 600
        }
    },
    setup: (props, { emit }) => {
        const $modal = ref<InstanceType<typeof BaseModal> | null>(null)
        const close = () => {
            if (!$modal.value) return undefined

            emit('on-close')
            $modal.value.close()
        }
        const open = () => {
            if (!$modal.value) return undefined

            $modal.value.open()
            emit('on-open')
        }

        const chatSizes = computed(() => ({
            'max-width': `${props.maxWidth}px`
        }))

        return {
            $modal,
            chatSizes,
            open,
            close
        }
    }
})
