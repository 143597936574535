var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 16 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M7.392.316a6.75 6.75 0 11-5.426 8.927.75.75 0 111.415-.498A5.25 5.25 0 104.607 3.3L2.893 4.91H5a.75.75 0 110 1.5H1a.75.75 0 01-.75-.75v-4a.75.75 0 011.5 0v2.267l1.822-1.712a6.75 6.75 0 013.82-1.9z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }