var render = function () {
  var _vm$partner, _vm$partner$company;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-chat-modal', _vm._g(_vm._b({
    ref: "$modal",
    staticClass: "partner-chat-modal",
    attrs: {
      "description": "Чат с персональным менеджером",
      "show-file-upload": false,
      "fetch-method": _vm.fetchMessages,
      "read-method": _vm.readMessage,
      "send-method": _vm.sendMessage,
      "title": (_vm$partner = _vm.partner) === null || _vm$partner === void 0 ? void 0 : (_vm$partner$company = _vm$partner.company) === null || _vm$partner$company === void 0 ? void 0 : _vm$partner$company.name,
      "user-id": _vm.user.id
    }
  }, 'app-chat-modal', _vm.$props, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }