import { render, staticRenderFns } from "./-ChatInput.vue?vue&type=template&id=2a92c59a&scoped=true&"
import script from "./-ChatInput.vue?vue&type=script&lang=ts&"
export * from "./-ChatInput.vue?vue&type=script&lang=ts&"
import style0 from "./-ChatInput.vue?vue&type=style&index=0&id=2a92c59a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a92c59a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppUpload: require('/var/www/app/src/components/ui/AppUpload/index.vue').default,AppInputSearch: require('/var/www/app/src/components/ui/AppInput/AppInputSearch.vue').default,AppList: require('/var/www/app/src/components/ui/AppList.vue').default,AppDropdown: require('/var/www/app/src/components/ui/AppDropdown.vue').default,AppEmoji: require('/var/www/app/src/components/ui/AppEmoji/index.vue').default,AppButton: require('/var/www/app/src/components/ui/AppButton.vue').default})
