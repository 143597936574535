

























import { PropType, computed, defineComponent } from '@nuxtjs/composition-api'

import { userFullName, user } from '@/composables/user'
import { httpLinkRegExp } from '@/composables/regexps'
import { RawLocation } from 'vue-router'

export default defineComponent({
    props: {
        profileLink: {
            type: [ String, Object ] as PropType<RawLocation | null>,
            default: null
        }
    },
    setup: (props) => {
        const linkType = computed(() => {
            if (!props.profileLink) {
                return 'div'
            }

            if (
                typeof props.profileLink === 'string'
                && httpLinkRegExp.test(props.profileLink || '')
            ) {
                return 'a'
            }

            return 'nuxt-link'
        })

        const displayName = computed(() => {
            const name = user.value.profile?.name
                || (user.value.profile?.familyName && ' ')
                || 'Пользователь'
            const familyName = user.value.profile?.familyName
                || (user.value.profile?.name && ' ')
                || 'Workle&nbsp;Pro'

            return `${name}<br>${familyName}`
        })

        return {
            userFullName,
            linkType,
            displayName,
            user
        }
    }

})
