
















import LogoutIcon from '@/assets/img/log-out.svg'

import { defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
    components: {
        LogoutIcon
    },
    setup: () => {
        const { $auth } = useContext()
        const logout = () => $auth.logout()

        return {
            logout
        }
    }
})
