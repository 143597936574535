var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.897 1.596c-.368 0-.716.141-.967.383l-9.266 8.91-.623 2.397 2.551-.613 9.272-8.915c.25-.241.386-.562.386-.89 0-.327-.135-.648-.386-.889a1.397 1.397 0 00-.967-.383zM10.89.898a2.896 2.896 0 012.007-.802c.747 0 1.47.285 2.007.802.538.517.846 1.225.846 1.97 0 .745-.308 1.453-.846 1.97l-9.419 9.057a.75.75 0 01-.344.189l-3.966.953a.75.75 0 01-.9-.918l.99-3.813a.75.75 0 01.207-.352L10.89.898z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }