var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat-message-quote"
  }, [_vm.title ? _c('div', {
    staticClass: "chat-message-quote__title"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "chat-message-quote__text"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }