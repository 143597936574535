


































import { MountingPortal } from 'portal-vue'

import {
    PropType, defineComponent, ref, computed, watch
} from '@nuxtjs/composition-api'

import AppDropdownMenu, {
    AppDropdownMenuSectionItem
} from '@/components/ui/AppDropdownMenu.vue'

import PencilIcon from '@/assets/img/pencil.svg'
import TrashcanIcon from '@/assets/img/trashcan.svg'
import { Message } from '@/interfaces/message.interface'

const messageActions = {
    Edit: { name: 'Редактировать', alias: 'edit' },
    Remove: { name: 'Удалить', alias: 'remove', color: 'red' },
    Response: { name: 'Ответить', alias: 'response' }
} as const

export type MessageAction = keyof typeof messageActions

export type Side = 'left' | 'right'

export default defineComponent({
    components: {
        MountingPortal
    },
    props: {
        leftActions: {
            type: Array as PropType<MessageAction[][]>,
            default: () => []
        },
        rightActions: {
            type: Array as PropType<MessageAction[][]>,
            default: () => []
        }
    },
    setup: (props, { emit }) => {
        const $menu = ref<InstanceType<typeof AppDropdownMenu> | null>(null)

        const selectedSide = ref<Side | null>(null)
        const transformActions = (actionsList: MessageAction[][]) => actionsList
            .map(
                section => section
                    .filter(item => messageActions[item] !== undefined)
                    .map(item => structuredClone(messageActions[item]))
            )
            .filter(section => section.length)

        const actions = computed(
            () => transformActions(
                selectedSide.value === 'left' ? props.leftActions : props.rightActions
            )
        )

        const position = ref({
            top: '',
            left: ''
        })

        const isOpen = ref(false)
        const selectedMessage = ref<Message | null>(null)
        const open = (
            event: PointerEvent,
            side: Side,
            message: Message
        ) => {
            isOpen.value = true
            selectedSide.value = side
            selectedMessage.value = message

            const { clientX, clientY } = event
            position.value = {
                top: `${clientY}px`,
                left: `${clientX}px`
            }
        }
        const close = () => {
            isOpen.value = false
            selectedSide.value = null
            selectedMessage.value = null

            position.value = {
                left: '',
                top: ''
            }
        }

        const onAction = (item: AppDropdownMenuSectionItem) => emit(
            `on-${item.alias}`,
            selectedMessage.value
        )
        const getActionIcon = (alias: 'remove' | 'edit') => ({
            remove: TrashcanIcon,
            edit: PencilIcon
        }[alias])

        watch(
            () => $menu.value,
            () => {
                if (!isOpen.value) return undefined

                $menu.value?.open()
            }
        )

        return {
            $menu,
            open,
            actions,
            getActionIcon,
            position,
            onAction,
            close,
            isOpen
        }
    }
})
