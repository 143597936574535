
















































































































import moment from 'moment'

import {
    computed, defineComponent, ref, PropType, nextTick
} from '@nuxtjs/composition-api'

import { Message } from '@/interfaces/message.interface'

import DownArrowIcon from '@/assets/img/arrow-down.svg'

interface DateGroup {
    [key: string]: Message[]
}

interface MessagesGroup {
    status: Message['readStatus']
    messages: Message[]
    from?: Message['userProfileDetails']
    messageType: Message['messageType']
}

interface GrouppedMessages {
    date: string
    messagesGroups: MessagesGroup[]
}

export default defineComponent({
    name: 'ChatMessagesList',
    components: {
        DownArrowIcon
    },
    props: {
        messages: {
            type: Array as PropType<Message[]>,
            default: () => []
        },
        userId: {
            type: String as PropType<string | null | undefined>,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        hideUnreadCaption: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        moment.locale('ru')

        const chatList = computed(() => {
            const groupedByDate = props.messages.reduce((groups: DateGroup, message) => {
                const date = moment(message.dateCreated, true).format('YYYY-MM-DD')

                if (!groups[date]) {
                    groups[date] = []
                }

                groups[date].push(message)

                return groups
            }, {})

            const grouppedBySender = Object.entries(groupedByDate).reduce(
                (groups: GrouppedMessages[], [ date, groupMesages ]) => {
                    let lastIndex = 0
                    let lastMessage: Message = groupMesages[0]
                    const messagesGroups: MessagesGroup[] = []

                    groupMesages.forEach((message, index) => {
                        const isExactId = lastMessage?.userProfileDetails?.id
                            === message.userProfileDetails?.id
                        const isExactStatus = lastMessage?.readStatus
                            === message.readStatus

                        if (
                            !isExactId
                            || !isExactStatus
                        ) {
                            messagesGroups.push({
                                status: lastMessage?.readStatus,
                                from: lastMessage?.userProfileDetails,
                                messages: groupMesages.slice(lastIndex, index),
                                messageType: lastMessage.messageType
                            })

                            lastIndex = index
                        }

                        if (index === groupMesages.length - 1) {
                            const actualMessage = !isExactId || !isExactStatus
                                ? message
                                : lastMessage

                            messagesGroups.push({
                                status: actualMessage?.readStatus,
                                from: actualMessage?.userProfileDetails,
                                messages: groupMesages.slice(lastIndex, index + 1),
                                messageType: actualMessage.messageType
                            })
                        }

                        lastMessage = message
                    })

                    groups.push({ date, messagesGroups })

                    return groups
                },
                []
            )

            return grouppedBySender
        })

        const dateToString = (date: string) => {
            const momented = moment(date)

            const isAnotherYear = momented.format('YYYY') !== moment().format('YYYY')
            const daysDifference = moment().diff(date, 'days')

            if (daysDifference === 0) {
                return 'Сегодня'
            }

            if (daysDifference === 1) {
                return 'Вчера'
            }

            return moment(date).format(`D MMMM${isAnotherYear ? ' YYYY' : ''}`)
        }

        const $list = ref<HTMLElement | null>(null)
        const $newCaption = ref<HTMLElement[] | null>(null)
        const scrollToBottom = (smooth: boolean = true) =>
            nextTick(() => {
                $list.value?.scrollTo({
                    top: 0,
                    behavior: smooth ? 'smooth' : 'auto'
                })
            })
        const scrollToUnreadMesages = () =>
            nextTick(() =>
                $newCaption.value?.[0]?.scrollIntoView({
                    block: 'end'
                }))

        const scrolledSize = ref(0)
        const isLoadMore = ref(false)
        const onScroll = (event: Event) => {
            const target = (event.target as HTMLDivElement)

            scrolledSize.value = target.scrollTop

            isLoadMore.value =
                (
                    target.scrollTop + 20 + target.clientHeight
                ) >= target.scrollHeight

            if (isLoadMore.value) {
                emit('load-more')
            }

            emit('on-scroll', event)
        }

        const onWheel = (event: WheelEvent) => {
            if (!$list.value) return undefined

            $list.value.scrollTop += -event.deltaY
        }

        const getMessageType = (messageGroup: MessagesGroup) => {
            if (messageGroup.messageType === 'Info') {
                return 'info-message'
            }

            if (messageGroup.from?.id === props.userId) {
                return 'right-message'
            }

            return 'left-message'
        }

        return {
            $list,
            $newCaption,
            chatList,
            scrolledSize,
            getMessageType,
            scrollToBottom,
            dateToString,
            scrollToUnreadMesages,
            onWheel,
            onScroll
        }
    }
})
