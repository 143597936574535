import { render, staticRenderFns } from "./PartnerChatModal.vue?vue&type=template&id=22c6077a&"
import script from "./PartnerChatModal.vue?vue&type=script&lang=ts&"
export * from "./PartnerChatModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppChatModal: require('/var/www/app/src/components/ui/AppChatModal.vue').default})
