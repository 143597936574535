














import { RawLocation } from 'vue-router'

import { defineComponent, PropType } from '@nuxtjs/composition-api'

import CatalogProfile from '@/components/partner/TheCatalog/-CatalogProfile.vue'
import CatalogLogoutButton from '@/components/partner/TheCatalog/-CatalogLogoutButton.vue'
import { closeAside } from '@/composables/asides'

export default defineComponent({
    components: {
        CatalogProfile,
        CatalogLogoutButton
    },
    props: {
        profileLink: {
            type: [ String, Object ] as PropType<RawLocation | null>,
            default: null
        }
    },
    setup: () => ({
        closeAside
    })
})
