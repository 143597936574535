var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-modal', {
    ref: "$modal",
    attrs: {
      "name": "app-chat-modal",
      "background-unclosable": ""
    },
    scopedSlots: _vm._u([{
      key: "background",
      fn: function () {
        return [_c('div', {
          staticClass: "app-chat-modal__background"
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "app-chat-modal__content",
          style: _vm.chatSizes
        }, [_c('app-chat', _vm._g(_vm._b({
          staticClass: "app-chat-modal__chat",
          attrs: {
            "closable": "",
            "show-file-upload": _vm.showFileUpload
          },
          on: {
            "on-close": _vm.close
          }
        }, 'app-chat', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }