var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mounting-portal', {
    attrs: {
      "append": "",
      "slim": "",
      "mount-to": "body"
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isOpen ? _c('div', {
    staticClass: "app-emoji-body-area",
    style: _vm.position
  }, [_c('app-input-search', {
    staticClass: "app-emoji-body-area__input",
    attrs: {
      "name": "search",
      "placeholder": "Поиск"
    },
    on: {
      "on-input": _vm.onSearch
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _vm._v(" "), _c('app-divider', {
    staticClass: "app-emoji-body-area__divider--search"
  }), _vm._v(" "), _c('emoji-list', {
    staticClass: "app-emoji-body-area__body",
    attrs: {
      "emoji-list": _vm.newEmojisList,
      "is-search": _vm.isSearch,
      "current-category": _vm.category
    },
    on: {
      "pick-emoji": _vm.pickEmoji
    }
  }), _vm._v(" "), _c('app-divider', {
    staticClass: "app-emoji-body-area__divider"
  }), _vm._v(" "), _c('emoji-control-area', {
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }