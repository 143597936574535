import { Components as Admin } from '@/types/client.admin'

export const getRequestAlias = (link?: string | undefined | null) => {
    if (!link || link === 'null') {
        return '-'
    }

    const alias = link.split('/')

    return alias[alias.length - 1]
}

export const isCanRedirect = (text: string | undefined | null) => {
    if (!text || text === 'null') {
        return undefined
    }

    return text
}

export const getMark = (mark: Admin.Schemas.TicketCreator | undefined) => {
    if (mark !== 'Email') {
        return '-'
    }

    return 'Почта'
}

export const ticketStatuses = [
    'Open',
    'InProgress',
    'AwaitingUserResponse',
    'Request',
    'Resolved'
]

export const ticketSourse = [
    {
        value: undefined,
        caption: 'Не выбрано'
    },
    {
        value: 'Email',
        caption: 'Почта'
    }
]

export const statusTabs = [
    {
        text: 'Открыто',
        value: 'Open'
    },
    {
        text: 'Ожидает ответа от пользователя',
        value: 'AwaitingUserResponse'
    },
    {
        text: 'В работе',
        value: 'InProgress'
    },
    {
        text: 'Решено',
        value: 'Resolved'
    },
    {
        text: 'Назначено исполнителю',
        value: 'Request'
    }
]

export const getStatusProperties = (status: string) => {
    const statuses: { [key: string]: { text: string, color: string } } = {
        Open: {
            text: 'Открыто',
            color: '#049FFF'
        },
        InProgress: {
            text: 'В работе',
            color: '#F88C0D'
        },
        AwaitingUserResponse: {
            text: 'Ожидает ответа от пользователя',
            color: '#FF2C2C'
        },
        Request: {
            text: 'Назначено исполнителю',
            color: '#F88C0D'
        },
        Resolved: {
            text: 'Решено',
            color: '#02C166'
        },
        Reopen: {
            text: 'Открыто',
            color: '#049FFF'
        }
    }

    return statuses[status] || status
}

export const getTopicSlice = (text: string) => {
    if (text.length > 160) {
        return `${text.slice(0, 160)}...`
    }

    return text
}
