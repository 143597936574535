var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 25 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M19.348 18.99a10.127 10.127 0 00.287-.304l.073-.083.136-.157.049-.058a9.899 9.899 0 002.27-5.531l.003-.028a9.062 9.062 0 00.03-.544l.001-.063a10.124 10.124 0 00-.031-1.048l-.002-.018a9.92 9.92 0 00-2.893-6.223A9.936 9.936 0 0012.2 2.005a9.936 9.936 0 00-7.071 2.928 9.92 9.92 0 00-2.894 6.23v.007l-.012.149-.005.093a10.186 10.186 0 000 1.178l.006.097.01.148.002.012a9.9 9.9 0 002.289 5.562l.004.005c.063.075.127.15.192.222l.027.03a10.419 10.419 0 00.38.404l.031.03.07.067a9.804 9.804 0 00.422.389l.008.007a9.927 9.927 0 006.54 2.434c2.206 0 4.302-.71 6.03-2.02l.025-.018c.071-.055.142-.11.212-.167l.04-.033c.076-.06.15-.123.223-.186l.077-.068a10.43 10.43 0 00.336-.31l.115-.112.014-.013.077-.08zm.713-1.641l-.105.15-.002.004a9.585 9.585 0 01-2.808 2.616c-1.043-2.35-1.594-5.135-1.594-8.075 0-2.97.561-5.779 1.624-8.143a9.585 9.585 0 012.353 2.046l.015.018a9.837 9.837 0 01.216.273 9.535 9.535 0 011.935 5.212l.005.084.008.198v.053a9.167 9.167 0 01-.008.687l-.005.077a9.524 9.524 0 01-1.635 4.8zm-15.606.165l-.027-.038a9.525 9.525 0 01-1.723-4.92l-.005-.087a9.37 9.37 0 01-.009-.68l.001-.06.008-.193.005-.092.006-.094a9.536 9.536 0 011.975-5.171l.043-.056a9.586 9.586 0 012.494-2.221c1.064 2.363 1.625 5.172 1.625 8.143 0 2.938-.55 5.724-1.594 8.074a9.586 9.586 0 01-2.8-2.605zm3.224 2.85c1.084-2.422 1.657-5.293 1.657-8.32 0-3.058-.583-5.953-1.688-8.39A9.456 9.456 0 0112.2 2.494c1.648 0 3.199.421 4.552 1.162-1.105 2.436-1.688 5.33-1.688 8.39 0 3.026.572 5.897 1.657 8.32a9.458 9.458 0 01-4.521 1.144 9.457 9.457 0 01-4.521-1.145z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }