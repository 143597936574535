var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-emoji-list"
  }, [_vm._l(_vm.emojiList, function (item) {
    var _item$emojis;

    return !_vm.isSearch && (item === null || item === void 0 ? void 0 : (_item$emojis = item.emojis) === null || _item$emojis === void 0 ? void 0 : _item$emojis.length) !== 0 ? _c('div', {
      key: item === null || item === void 0 ? void 0 : item.id
    }, [_c('div', {
      ref: "$block",
      refInFor: true,
      staticClass: "app-emoji-list__title"
    }, [_vm._v("\n            " + _vm._s(item === null || item === void 0 ? void 0 : item.blockTitle) + "\n        ")]), _vm._v(" "), _c('div', {
      staticClass: "app-emoji-list__emoji-list"
    }, _vm._l(item === null || item === void 0 ? void 0 : item.emojis, function (emoji) {
      return _c('div', {
        staticClass: "app-emoji-list__emoji",
        attrs: {
          "title": emoji.title
        },
        domProps: {
          "innerHTML": _vm._s(emoji.emojiCode)
        },
        on: {
          "click": function ($event) {
            return _vm.pickEmoji(emoji.emojiCode);
          }
        }
      });
    }), 0)]) : _vm._e();
  }), _vm._v(" "), _c('div', {
    staticClass: "app-emoji-list__emoji-list"
  }, _vm._l(_vm.emojiList, function (item) {
    return _vm.isSearch ? _c('div', {
      staticClass: "app-emoji-list__emoji",
      attrs: {
        "title": item === null || item === void 0 ? void 0 : item.title
      },
      domProps: {
        "innerHTML": _vm._s(item === null || item === void 0 ? void 0 : item.emojiCode)
      },
      on: {
        "click": function ($event) {
          return _vm.pickEmoji(item.emojiCode);
        }
      }
    }) : _vm._e();
  }), 0)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }