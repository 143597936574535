var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['chat-user-caption', ("chat-user-caption--" + (_vm.align))]
  }, [_c('app-avatar', {
    staticClass: "chat-user-caption__avatar",
    attrs: {
      "name": _vm.fullName,
      "image": _vm.mainImage
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "chat-user-caption__name"
  }, [_vm._v("\n        " + _vm._s(_vm.fullName) + "\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }