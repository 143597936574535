import { render, staticRenderFns } from "./-ChatContextMenu.vue?vue&type=template&id=96218486&scoped=true&"
import script from "./-ChatContextMenu.vue?vue&type=script&lang=ts&"
export * from "./-ChatContextMenu.vue?vue&type=script&lang=ts&"
import style0 from "./-ChatContextMenu.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./-ChatContextMenu.vue?vue&type=style&index=1&id=96218486&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96218486",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDropdownMenu: require('/var/www/app/src/components/ui/AppDropdownMenu.vue').default})
