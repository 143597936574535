var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M5.06 2.26h-.14a.14.14 0 00.14-.14v.14h5.32v-.14c0 .077.063.14.14.14h-.14v1.26h1.26v-1.4c0-.618-.502-1.12-1.12-1.12h-5.6c-.618 0-1.12.502-1.12 1.12v1.4h1.26V2.26zm8.82 1.26H1.56a.56.56 0 00-.56.56v.56c0 .077.063.14.14.14h1.057l.432 9.152A1.12 1.12 0 003.748 15h7.944c.599 0 1.09-.469 1.119-1.068l.432-9.152H14.3a.14.14 0 00.14-.14v-.56a.56.56 0 00-.56-.56zm-2.322 10.22H3.882L3.46 4.78h8.522l-.423 8.96z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }