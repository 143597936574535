var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-emoji-control-area"
  }, _vm._l(_vm.controlItems, function (item) {
    return _c('div', [_c(item.icon, {
      key: item.id,
      tag: "component",
      class: ['app-emoji-control-area__icon', ("app-emoji-control-area__" + (item.id)), {
        'app-emoji-control-area__current': item.title === _vm.value
      }],
      attrs: {
        "title": item.title
      },
      on: {
        "click": function ($event) {
          return _vm.pickCategory(item.title);
        }
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }