var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 16 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M15.374.293a1 1 0 010 1.414l-9.167 9.167a1 1 0 01-1.414 0L.626 6.707A1 1 0 012.04 5.293l3.46 3.46 8.46-8.46a1 1 0 011.414 0z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }