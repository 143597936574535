
























import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

import BaseDropdown from '@/components/ui/BaseDropdown.vue'

export default defineComponent({
    props: {
        ...BaseDropdown.props,
        wide: {
            type: Boolean,
            default: false
        }
    },
    setup: () => {
        const $dropdown = ref<InstanceType<typeof BaseDropdown> | null>(null)
        const isOpen = computed(() => $dropdown.value?.isOpen)
        const open = () => $dropdown.value?.open()
        const close = () => $dropdown.value?.close()
        const toggle = () => $dropdown.value?.toggle()

        return {
            $dropdown,
            open,
            close,
            toggle,
            isOpen
        }
    }
})
