var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-list"
  }, [_vm._l(_vm.value, function (itemValue, index) {
    return _c('div', {
      key: index,
      staticClass: "app-list__item"
    }, [_vm._t("content", null, null, {
      index: index,
      value: itemValue,
      onInput: _vm.onInput.bind(undefined, index),
      deleteItem: _vm.deleteItem.bind(undefined, index)
    }), _vm._v(" "), _c('div', {
      staticClass: "app-list__item-action"
    }, [_vm._t("action", function () {
      return [_c('app-list-buttons', {
        attrs: {
          "count": _vm.value.length,
          "index": index,
          "hide-delete": _vm.hideDelete,
          "hide-move": !_vm.movable
        },
        on: {
          "on-move-up": function ($event) {
            return _vm.moveItem(index, index - 1);
          },
          "on-move-down": function ($event) {
            return _vm.moveItem(index, index + 1);
          },
          "on-delete": function ($event) {
            return _vm.deleteItem(index);
          }
        }
      })];
    }, null, {
      index: index,
      onDelete: _vm.deleteItem.bind(undefined, index),
      moveItem: _vm.moveItem,
      deleteItem: _vm.deleteItem
    })], 2)], 2);
  }), _vm._v(" "), !_vm.hideButton ? _vm._t("addItem", function () {
    return [_c('app-button', {
      staticClass: "app-list__button",
      attrs: {
        "size": "large",
        "disabled": _vm.disableButton
      },
      on: {
        "on-click": function ($event) {
          $event.preventDefault();
          return _vm.addItem.apply(null, arguments);
        }
      }
    }, [_c('plus-circle-icon', {
      staticClass: "app-list__button-icon"
    }), _vm._v(" "), _vm._t("button-caption", function () {
      return [_vm._v("\n                " + _vm._s(_vm.buttonCaption) + "\n            ")];
    })], 2)];
  }, null, {
    addItem: _vm.addItem,
    newVlue: _vm.newValue
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }