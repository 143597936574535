var render = function () {
  var _vm$image, _vm$image2, _vm$image3, _vm$image4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat-input"
  }, [_vm.selectedMessage ? _c('div', {
    staticClass: "chat-input__selected-message"
  }, [_c('div', {
    staticClass: "chat-input__selected-text-wrapper"
  }, [_c('div', {
    staticClass: "chat-input__selected-caption"
  }, [_vm._v("\n                " + _vm._s(_vm.selectedTitle) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "chat-input__selected-text"
  }, [_vm._v("\n                " + _vm._s(_vm.selectedMessage.message || _vm.selectedMessage.text || '') + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "chat-input__selected-cancel",
    on: {
      "click": _vm.onCancel
    }
  }, [_c('close-icon', {
    staticClass: "chat-input__selected-cancel-icon"
  })], 1)]) : _vm._e(), _vm._v(" "), (_vm$image = _vm.image) !== null && _vm$image !== void 0 && _vm$image.sourceFileName ? _c('div', {
    staticClass: "chat-input__selected-file"
  }, [_c('div', {
    staticClass: "chat-input__selected-file-wrapper"
  }, [_c('div', {
    staticClass: "chat-input__selected-file-text"
  }, [_c('check-icon', {
    staticClass: "chat-input__check-icon"
  }), _vm._v("\n                " + _vm._s((_vm$image2 = _vm.image) === null || _vm$image2 === void 0 ? void 0 : _vm$image2.sourceFileName) + "\n            ")], 1)]), _vm._v(" "), _c('div', {
    staticClass: "chat-input__selected-cancel",
    on: {
      "click": _vm.onClearFile
    }
  }, [_c('close-icon', {
    staticClass: "chat-input__selected-cancel-icon"
  })], 1)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "chat-input__input-wrapper"
  }, [_c('div', {
    staticClass: "chat-input__buttons-block"
  }, [_vm.showFileUpload ? _c('app-upload', {
    staticClass: "chat-input__upload",
    attrs: {
      "disabled": _vm.disabled,
      "name": "coverImage",
      "use-self-interface": "",
      "on-upload": _vm.uploadImageFile
    },
    model: {
      value: _vm.image,
      callback: function ($$v) {
        _vm.image = $$v;
      },
      expression: "image"
    }
  }, [_c('paperclip-icon', {
    staticClass: "chat-input__upload-icon",
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  })], 1) : _vm._e(), _vm._v(" "), _vm.showChangeManagerButton ? _c('app-dropdown', {
    ref: "$dropdown",
    staticClass: "chat-input__dropdown",
    attrs: {
      "placement": "top-start",
      "flip": "x",
      "wide": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "chat-input__icon-block",
          on: {
            "click": _vm.openChangeManagerDropdown
          }
        }, [_c('change-manager-icon', {
          staticClass: "chat-input__link-icon"
        })], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "chat-input__dropdown-content"
        }, [_c('admin-title', {
          staticClass: "chat-input__dropdown-title"
        }, [_vm._v("\n                            Передать диалог\n                        ")]), _vm._v(" "), _c('app-input-search', {
          staticClass: "chat-input__dropdown-input",
          attrs: {
            "name": "search",
            "placeholder": "Поиск по имени и фамилии"
          },
          model: {
            value: _vm.searchManager,
            callback: function ($$v) {
              _vm.searchManager = $$v;
            },
            expression: "searchManager"
          }
        }), _vm._v(" "), _c('app-list', {
          staticClass: "chat-input__dropdown-list",
          attrs: {
            "hide-delete": "",
            "hide-button": ""
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function (_ref) {
              var value = _ref.value;
              return [_c('div', {
                staticClass: "chat-input__dropdown-list-item",
                on: {
                  "click": function ($event) {
                    return _vm.selectManager(value);
                  }
                }
              }, [_vm._v("\n                                    " + _vm._s(value.fullName || value.id) + "\n                                ")])];
            }
          }], null, false, 3769267569),
          model: {
            value: _vm.filteredManagers,
            callback: function ($$v) {
              _vm.filteredManagers = $$v;
            },
            expression: "filteredManagers"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1801016053)
  }) : _vm._e(), _vm._v(" "), _vm.showFastLinkButton ? _c('div', {
    staticClass: "chat-input__icon-block",
    on: {
      "click": _vm.onLinkFastFill
    }
  }, [_c('link-icon', {
    staticClass: "chat-input__link-icon"
  })], 1) : _vm._e(), _vm._v(" "), _vm.showEmojiPicker ? _c('div', {
    staticClass: "chat-input__icon-block"
  }, [_c('app-emoji', {
    on: {
      "pick-emoji": _vm.pickEmoji
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('textarea', {
    ref: "$core",
    staticClass: "chat-input__core",
    attrs: {
      "disabled": _vm.disabled || !!((_vm$image3 = _vm.image) !== null && _vm$image3 !== void 0 && _vm$image3.sourceFileName),
      "placeholder": _vm.placeholder,
      "maxlength": _vm.maxLength
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.onInput,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) { return null; }
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c('app-button', {
    staticClass: "chat-input__button",
    attrs: {
      "wide": "",
      "disabled": _vm.disabled || _vm.disabledByText && !((_vm$image4 = _vm.image) !== null && _vm$image4 !== void 0 && _vm$image4.sourceFileName),
      "loading": _vm.loading
    },
    on: {
      "on-click": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "chat-input__button-caption"
  }, [_vm._v("\n                " + _vm._s(_vm.sendButtonCaption) + "\n            ")]), _vm._v(" "), _c('paper-plane-icon', {
    staticClass: "chat-input__button-icon"
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }