













import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    }
})
