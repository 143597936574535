var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mounting-portal', {
    attrs: {
      "append": "",
      "slim": "",
      "mount-to": "body"
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isOpen && _vm.actions.length ? _c('app-dropdown-menu', {
    ref: "$menu",
    staticClass: "chat-context-menu",
    style: _vm.position,
    attrs: {
      "arrow": false,
      "sections": _vm.actions
    },
    on: {
      "on-click": _vm.onAction,
      "on-close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "chat-context-menu__action"
        }, [_c(_vm.getActionIcon(item.alias), {
          tag: "component",
          class: ['chat-context-menu__action-icon', ("chat-context-menu__action-icon--" + (item.alias))]
        }), _vm._v("\n                    " + _vm._s(item.name) + "\n                ")], 1)];
      }
    }], null, false, 1865871737)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }