var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 20 20",
      "stroke": "#fff",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('g', {
    attrs: {
      "clip-path": "url(#link_svg__clip0_897_4192)",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.333 10.834a4.166 4.166 0 006.284.45l2.5-2.5a4.167 4.167 0 00-5.892-5.892L9.792 4.317"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.667 9.167a4.167 4.167 0 00-6.284-.45l-2.5 2.5a4.167 4.167 0 005.892 5.891l1.425-1.425"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "link_svg__clip0_897_4192"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M0 0h20v20H0z"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }