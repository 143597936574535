


































import { MountingPortal } from 'portal-vue'

import {
    defineComponent,
    onMounted,
    ref,
    watch
} from '@nuxtjs/composition-api'

import EmojiControlArea from '@/components/ui/AppEmoji/-AppEmojiControlArea.vue'
import EmojiList from '@/components/ui/AppEmoji/-AppEmojiList.vue'
import { emojiList } from '@/data/emoji-list'

export default defineComponent({
    components: {
        MountingPortal,
        EmojiControlArea,
        EmojiList
    },
    setup: (_props, { emit }) => {
        const isOpen = ref(false)
        const isSearch = ref(false)
        const searchQuery = ref('')
        const category = ref()
        const oftenUsed = ref([])
        const position = ref({
            top: '',
            left: ''
        })
        const newEmojisList = ref()

        const onSearch = () => {
            isSearch.value = true

            if (searchQuery.value === '') {
                isSearch.value = false
                newEmojisList.value = emojiList.value
            } else {
                newEmojisList.value = emojiList.value.map(
                    el => el.emojis.filter(elem => elem?.title
                        ?.toLowerCase()
                        .includes(searchQuery.value.toLowerCase())
                    )
                ).flat()
            }
        }

        const open = (event: PointerEvent) => {
            if (isOpen.value) {
                close()
            } else {
                isOpen.value = true
            }

            const { clientX, clientY } = event
            position.value = {
                top: `${clientY - 420}px`,
                left: `${clientX}px`
            }
        }

        const pickEmoji = (code: string) => {
            const maxEmojiInOftenUsedArray = 13

            const isDuplicate = oftenUsed.value
                .map(emoji => emoji.emojiCode === code)
                .includes(true)

            oftenUsed.value.splice(maxEmojiInOftenUsedArray)
            oftenUsed.value.unshift({ emojiCode: code })

            const newItems = JSON.stringify(oftenUsed.value)

            if (!isDuplicate) {
                localStorage.setItem('often-used', newItems)
            }

            getOftenUsedData()
            emit('pick-emoji', code)
            close()
        }

        const close = () => {
            isOpen.value = false
            category.value = ''
            position.value = {
                left: '',
                top: ''
            }
        }

        const getOftenUsedData = () => {
            const oftenUsedBlock = emojiList.value
                ?.find(el => el.blockTitle === 'Часто используемые')
                ?.emojis

            oftenUsedBlock?.splice(0, oftenUsedBlock.length)

            const storedData = localStorage.getItem('often-used')

            if (storedData) {
                oftenUsed.value = JSON.parse(storedData)
                oftenUsedBlock?.push(...oftenUsed.value)
            }
        }

        watch(
            () => searchQuery.value,
            () => onSearch()
        )

        onMounted(() => {
            getOftenUsedData()
            newEmojisList.value = emojiList.value
        })

        return {
            open,
            close,
            onSearch,
            pickEmoji,
            oftenUsed,
            category,
            searchQuery,
            isSearch,
            emojiList,
            newEmojisList,
            isOpen,
            position
        }
    }
})
