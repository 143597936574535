



















import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        small: {
            type: Boolean,
            default: false
        },
        color: {
            type: String as PropType<'blue' | 'whie' | 'black'>,
            default: 'blue'
        }
    }
})
