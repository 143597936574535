

















































































import {
    defineComponent,
    ref,
    PropType,
    toRefs,
    onMounted
} from '@nuxtjs/composition-api'

import { Message } from '@/interfaces/message.interface'
import { useScrollableCollection } from '@/composables/scrollable-collection'
import {
    EditCallback,
    FetchCallback,
    ReadCallback,
    RemoveCallback,
    SendCallback
} from '@/types/chat'

import { openModal } from '@/composables/result-modal'
import { getStatusProperties } from '@/composables/ticketsOptions'

import { OperationMethods } from '@/types/client.customer'

import WarnIcon from '@/assets/img/circled-warn.svg'

import ChatHeader from './-ChatHeader.vue'
import ChatInput from './-ChatInput.vue'
import ChatMessage from './-ChatMessage.vue'
import ChatMessagesList from './-ChatMessagesList.vue'
import ChatUserCaption from './-ChatUserCaption.vue'
import ChatContextMenu, { Side } from './-ChatContextMenu.vue'

type SelectionType = 'Edit' | 'Response'

export default defineComponent({
    components: {
        ChatHeader,
        ChatInput,
        ChatMessage,
        ChatMessagesList,
        ChatUserCaption,
        ChatContextMenu,
        WarnIcon
    },
    props: {
        ...ChatContextMenu.props,
        fetchMethod: {
            type: Function as PropType<FetchCallback>,
            required: true
        },
        sendMethod: {
            type: Function as PropType<SendCallback | null>,
            default: null
        },
        readMethod: {
            type: Function as PropType<ReadCallback | null>,
            default: null
        },
        editMethod: {
            type: Function as PropType<EditCallback | null>,
            default: null
        },
        removeMethod: {
            type: Function as PropType<RemoveCallback | null>,
            default: null
        },
        uploadMethod: {
            type: Function as PropType<OperationMethods[]>,
            default: null
        },
        title: {
            type: String,
            default: 'Чат'
        },
        description: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: false
        },
        userId: {
            type: String,
            default: ''
        },
        showFastLinkButton: {
            type: Boolean,
            default: true
        },
        showEmojiPicker: {
            type: Boolean,
            default: true
        },
        showFileUpload: {
            type: Boolean,
            default: true
        },
        showChangeManagerButton: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const messageText = ref('')

        const $list = ref<InstanceType<typeof ChatMessagesList> | null>(null)
        const scrollToBottom = (smooth: boolean = false) => $list.value?.scrollToBottom(smooth)
        const scrollToUnreadMesages = () => $list.value?.scrollToUnreadMesages()

        const $input = ref<InstanceType<typeof ChatInput> | null>(null)
        const inputDisabled = ref(false)

        const $contextMenu = ref<InstanceType<typeof ChatContextMenu> | null>(null)
        const openMenu = (event: PointerEvent, message: Message, side: Side) => {
            if (
                (side === 'right' && !props.rightActions?.length)
                || (side === 'left' && !props.leftActions?.length)
            ) {
                return undefined
            }

            $contextMenu.value?.open(event, side, message)
        }

        const {
            fetch,
            onScroll,
            loadMore,
            isLoading: messagesLoading,
            items: messages
        } = useScrollableCollection(props.fetchMethod, {
            joinMethod: 'Unshift',
            reversedScroll: false,
            autoScroll: true
        })
        const isLoading = ref({
            ...toRefs(messagesLoading),
            send: false
        })

        let readTimer: number | null = null
        const readAllMessages = async (timer: number = 0) => {
            if (readTimer) {
                window.clearTimeout(readTimer)
            }

            readTimer = window.setTimeout(async () => {
                if (!props.readMethod) return undefined

                const chatMessageIds = messages.value?.reduce(
                    (total: string[], item, index) => {
                        const { id, readStatus } = item

                        if (readStatus === 'Read' || !id) {
                            return total
                        }

                        if (messages.value) {
                            messages.value[index].readStatus = 'Read'
                        }

                        total.push(id)

                        return total
                    },
                    []
                )

                if (!chatMessageIds?.length) {
                    return undefined
                }

                await props.readMethod(chatMessageIds)

                emit('on-read')
            }, timer)
        }

        const selectedMessage = ref<Message | null>(null)
        const selectedTitle = ref('')
        const selectionType = ref<SelectionType | null>(null)
        const sendCaption = ref<string>()
        const selectMessage = (
            type: SelectionType,
            message: Message,
            title: string,
            focus: boolean = true
        ) => {
            selectedTitle.value = title
            selectedMessage.value = message
            selectionType.value = type
            sendCaption.value = {
                Response: 'Ответить',
                Edit: 'Изменить'
            }[type]

            if (!focus) {
                return undefined
            }

            $input.value?.focus()
        }
        const cancelSelect = () => {
            selectedMessage.value = null
            selectionType.value = null
            sendCaption.value = undefined
            selectedTitle.value = ''
        }

        const startResponse = (message: Message) =>
            selectMessage('Response', message, 'Ответ на сообщение:')

        const fetchMessages = async () => {
            try {
                await fetch()

                const hasUnread = messages.value.some(
                    message => message.readStatus === 'Unread'
                )

                if (hasUnread) {
                    scrollToUnreadMesages()
                }

                readAllMessages(3000)
            } catch (error) {
                openModal({ type: 'error' })
                console.error(error)
            }
        }

        const startEdit = (message: Message) => {
            selectMessage('Edit', message, 'Редактирование сообщения:')
            messageText.value = message.message || message.text || ''
        }
        const editMessage = async () => {
            try {
                if (!props.editMethod || !selectedMessage.value) return undefined

                const response = await props.editMethod(
                    selectedMessage.value,
                    messageText.value
                )
                const { message, dateUpdated } = response.data || response
                const chatMessage = messages.value.find(
                    item => item.id === selectedMessage.value?.id
                )

                if (!chatMessage) {
                    return undefined
                }

                chatMessage.message = message
                chatMessage.dateUpdated = dateUpdated
                chatMessage.text = messageText.value

                cancelSelect()
            } catch (error) {
                openModal({ type: 'error' })
                console.error(error)
            }
        }

        const sendMessage = async () => {
            if (!props.sendMethod) return undefined

            const response = await props.sendMethod(
                messageText.value,
                selectedMessage.value || undefined
            )
            const newMessage = response.data || response
            newMessage.readStatus = 'Read'

            messages.value?.push(newMessage)
            scrollToBottom(true)
        }

        const removeMessage = async (removingMessage: Message) => {
            try {
                if (!props.removeMethod || !removingMessage.id) return undefined

                inputDisabled.value = true

                await props.removeMethod(removingMessage.id)

                const messageIndex = messages.value.findIndex(
                    message => message.id === removingMessage.id
                )

                if (messageIndex === -1) {
                    return undefined
                }

                messages.value.splice(messageIndex, 1)
            } catch (error) {
                openModal({ type: 'error' })
                console.error(error)
            } finally {
                inputDisabled.value = false
            }
        }

        const onSubmitMessage = async () => {
            const timer = setTimeout(() => {
                isLoading.value.send = true
            }, 1000)

            try {
                if (!props.sendMethod) return undefined

                inputDisabled.value = true
                readAllMessages()

                if (selectionType.value === 'Edit') {
                    await editMessage()
                } else {
                    await sendMessage()
                }

                cancelSelect()
                messageText.value = ''
            } catch (error) {
                openModal({ type: 'error' })
                console.error(error)
            } finally {
                clearInterval(timer)
                inputDisabled.value = false
                isLoading.value.send = false
            }
        }

        const getMessageStatus = (message: string) => {
            let formattedMessage = message

            if (formattedMessage === 'Reopen') {
                formattedMessage = 'Open'
            }

            return `Статус обращения изменен на «${getStatusProperties(formattedMessage).text}»`
        }

        onMounted(fetchMessages)

        return {
            $contextMenu,
            $input,
            $list,
            isLoading,
            inputDisabled,
            messages,
            selectedMessage,
            messageText,
            selectedTitle,
            sendCaption,
            onSubmitMessage,
            openMenu,
            getMessageStatus,
            readAllMessages,
            selectMessage,
            removeMessage,
            onScroll,
            loadMore,
            startResponse,
            startEdit,
            cancelSelect
        }
    }
})
