var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "the-mobile-profile",
    on: {
      "click": _vm.closeAside
    }
  }, [_c('catalog-profile', {
    staticClass: "the-mobile-profile__profile",
    attrs: {
      "profile-link": _vm.profileLink
    }
  }), _vm._v(" "), _c('app-divider', {
    staticClass: "the-mobile-profile__divider"
  }), _vm._v(" "), _c('catalog-logout-button', {
    staticClass: "catalog-logout-button"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }