var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M8.225.7l.717-.697A1 1 0 008.225-.3v1zm-4.9 0v-1 1zM2.1 1.96h-1 1zm0 10.08h-1 1zm9.8-7.56h1a1 1 0 00-.283-.697l-.717.697zM9.4 1.5v-1h-2v1h2zm-1 2.7h-1a1 1 0 001 1v-1zm2.6 1h1v-2h-1v2zM8.225-.3h-4.9v2h4.9v-2zm-4.9 0c-.6 0-1.168.245-1.583.672l1.434 1.394a.208.208 0 01.149-.066v-2zM1.742.372A2.278 2.278 0 001.1 1.96h2c0-.078.03-.147.076-.194L1.742.372zM1.1 1.96v10.08h2V1.96h-2zm0 10.08c0 .59.228 1.162.642 1.588l1.434-1.394a.279.279 0 01-.076-.194h-2zm.642 1.588c.415.427.984.672 1.583.672v-2a.208.208 0 01-.15-.066l-1.433 1.394zm1.583.672h7.35v-2h-7.35v2zm7.35 0c.6 0 1.168-.245 1.583-.672l-1.434-1.394a.208.208 0 01-.149.066v2zm1.583-.672c.414-.426.642-.998.642-1.588h-2c0 .078-.03.147-.076.194l1.434 1.394zm.642-1.588V4.48h-2v7.56h2zm-.283-8.257L8.942.003 7.508 1.397l3.675 3.78 1.434-1.394zM7.4 1.5v2.7h2V1.5h-2zm1 3.7H11v-2H8.4v2z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }