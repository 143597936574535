var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 12 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M10.872.328a1.12 1.12 0 010 1.584l-5.76 5.76a1.12 1.12 0 01-1.584 0l-3.2-3.2a1.12 1.12 0 011.584-1.584L4.32 5.296 9.288.328a1.12 1.12 0 011.584 0z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }