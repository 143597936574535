var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'app-spinner': true,
      'app-spinner--small': _vm.small
    }
  }, [_c('div', {
    class: ['app-spinner__spinner', ("app-spinner__spinner--" + (_vm.color))]
  }), _vm._v(" "), !_vm.small ? _vm._t("default", function () {
    return [_vm._v("\n        Загрузка\n    ")];
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }