var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M12.8 3a9 9 0 100 18 9 9 0 000-18zm-11 9c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11-11-4.925-11-11z"
    }
  }), _c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M9.597 13.396A1 1 0 008 14.6l.8-.6-.8.6.001.001.001.002.003.004.007.009.021.027.07.086a6.942 6.942 0 001.171 1.08c.798.58 2 1.191 3.526 1.191 1.525 0 2.728-.61 3.526-1.191a6.944 6.944 0 001.17-1.08c.03-.035.053-.064.07-.086l.022-.027.007-.01.003-.003.001-.002S17.6 14.6 16.8 14l.8.6a1 1 0 00-1.597-1.204l-.005.006a3.709 3.709 0 01-.194.219 4.953 4.953 0 01-.655.57c-.577.42-1.374.809-2.349.809-.975 0-1.772-.39-2.35-.809a4.953 4.953 0 01-.815-.748 1.932 1.932 0 01-.034-.041l-.004-.006zM8.8 9a1 1 0 011-1h.01a1 1 0 110 2H9.8a1 1 0 01-1-1zM14.8 9a1 1 0 011-1h.01a1 1 0 110 2h-.01a1 1 0 01-1-1z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }