/* eslint-disable import/no-extraneous-dependencies */
import { ref } from '@nuxtjs/composition-api'
import { Components as Partner } from '@/types/client.partner'
import { Context } from '@nuxt/types'
import { useSinglePromise } from '@/composables/single-promise'

export const $partnerChatModal = ref<any | null>(null)
export const openPartnerChat = () => $partnerChatModal.value?.open()

export type ExtendedPartnerType = Partner.Schemas.PartnerRequestStatus | 'Unknown'

type PartnerTypeInfo = {
    [key in ExtendedPartnerType]: string
}

interface PartnerState {
    company: Partner.Schemas.PartnerDetails | null
    messagesCount:number
    statistics: Partner.Schemas.PartnerProduct[]
}

export const partner = ref<PartnerState>({
    company: null,
    messagesCount: 0,
    statistics: []
})

export const partnerStatisticsStatus: PartnerTypeInfo = {
    Request: 'Заявка',
    Closed: 'Заявка закрыта',
    Transition: 'Переход',
    Deal: 'Сделка',
    Unknown: ''
}

export const fetchCompany = useSinglePromise(async (context: unknown) => {
    const { $apiPartner } = context as Context

    const { data: [ firstCompany ] } = await $apiPartner.partnerMembersGetPartnersForUser()

    if (!firstCompany || !firstCompany.id) {
        return undefined
    }

    const { data } = await $apiPartner.partnerDetailsGet(firstCompany.id)

    partner.value.company = data
})

export const fetchSatistics = useSinglePromise(async (context: unknown) => {
    const { $apiPartner } = context as Context

    if (!partner.value.company) {
        await fetchCompany(context)
    }

    if (!partner.value.company) {
        return undefined
    }

    const {
        data = []
    } = await $apiPartner.productsGetProductsMarkedForStatistics(partner.value.company.id)

    partner.value.statistics = data
})
