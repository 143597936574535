/* eslint-disable import/no-extraneous-dependencies */
import { CatalogItem } from '@/interfaces/catalog.interface'
import { NavigationItem } from '@/interfaces/navigation.interface'
import http from 'axios'

export const fetchFeatured = async (portal: BundleType) => {
    const { data = [] } = await http.get(`/featured/${portal}`)

    return data as CatalogItem[]
}

export const fetchCatalog = async (portal: BundleType) => {
    const { data = [] } = await http.get(`/catalog/${portal}`)

    return data as CatalogItem[]
}

export const fetchNavigation = async (portal: BundleType) => {
    const { data = [] } = await http.get(`/navigation/${portal}`)

    return data as NavigationItem[]
}
