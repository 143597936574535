var render = function () {
  var _vm$$auth$user;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-chat"
  }, [_c('chat-header', {
    staticClass: "app-chat__header",
    attrs: {
      "closable": _vm.closable,
      "description": _vm.description,
      "title": _vm.title,
      "hint": _vm.hint
    },
    on: {
      "on-close": function ($event) {
        return _vm.$emit('on-close', $event);
      }
    }
  }), _vm._v(" "), _c('chat-messages-list', {
    ref: "$list",
    staticClass: "app-chat__messages-list",
    attrs: {
      "user-id": _vm.userId || ((_vm$$auth$user = _vm.$auth.user) === null || _vm$$auth$user === void 0 ? void 0 : _vm$$auth$user.sub),
      "messages": _vm.messages,
      "loading": _vm.isLoading.fetch || _vm.isLoading.more,
      "hide-unread-caption": !_vm.readMethod
    },
    on: {
      "load-more": _vm.loadMore
    },
    scopedSlots: _vm._u([{
      key: "left-name",
      fn: function (nameScope) {
        return [_c('chat-user-caption', _vm._b({}, 'chat-user-caption', nameScope, false))];
      }
    }, {
      key: "left-message",
      fn: function (message) {
        return [_c('chat-message', {
          staticClass: "app-chat__message",
          attrs: {
            "message": message,
            "position": "left",
            "type": "white"
          },
          on: {
            "on-open-contextmenu": function ($event) {
              return _vm.openMenu($event, message, 'left');
            }
          }
        })];
      }
    }, {
      key: "right-name",
      fn: function (nameScope) {
        return [_c('chat-user-caption', _vm._b({
          attrs: {
            "align": "right",
            "no-name": "Я"
          }
        }, 'chat-user-caption', nameScope, false))];
      }
    }, {
      key: "right-message",
      fn: function (message) {
        return [_c('chat-message', {
          staticClass: "app-chat__message",
          attrs: {
            "message": message,
            "position": "right",
            "type": "black"
          },
          on: {
            "on-open-contextmenu": function ($event) {
              return _vm.openMenu($event, message, 'right');
            }
          }
        })];
      }
    }, {
      key: "info-message",
      fn: function (message) {
        return [_c('div', {
          staticClass: "app-chat__info-message"
        }, [_c('warn-icon', {
          staticClass: "app-chat__warn-icon"
        }), _vm._v("\n                " + _vm._s(_vm.getMessageStatus(message.text)) + "\n            ")], 1)];
      }
    }])
  }), _vm._v(" "), _c('chat-input', {
    ref: "$input",
    attrs: {
      "disabled": _vm.disabled || _vm.inputDisabled || !_vm.sendMethod,
      "loading": _vm.isLoading.send,
      "selected-message": _vm.selectedMessage,
      "selected-title": _vm.selectedTitle,
      "send-button-caption": _vm.sendCaption,
      "show-fast-link-button": _vm.showFastLinkButton,
      "show-file-upload": _vm.showFileUpload,
      "show-emoji-picker": _vm.showEmojiPicker,
      "show-change-manager-button": _vm.showChangeManagerButton,
      "upload-method": _vm.uploadMethod
    },
    on: {
      "on-cancel": _vm.cancelSelect,
      "on-submit": _vm.onSubmitMessage
    },
    model: {
      value: _vm.messageText,
      callback: function ($$v) {
        _vm.messageText = $$v;
      },
      expression: "messageText"
    }
  }), _vm._v(" "), _c('chat-context-menu', {
    ref: "$contextMenu",
    attrs: {
      "left-actions": _vm.leftActions,
      "right-actions": _vm.rightActions
    },
    on: {
      "on-edit": _vm.startEdit,
      "on-remove": _vm.removeMessage,
      "on-response": _vm.startResponse
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }