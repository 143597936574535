




















































import {
    computed, defineComponent, PropType, ref, watch
} from '@nuxtjs/composition-api'
import { allMimeTypes, MIMEType } from '@/composables/mime-types'
import {
    UploadFunction, FileWrapper, toFileWrapper, BinaryContent, getUploadResult
} from '@/composables/files'

type Value = BinaryContent | File | string | number | null
type TransformCallback = <T = unknown>(wrappers: FileWrapper[] | FileWrapper) => T

const defaultUploader: UploadFunction = (wrapper, setUploaded) => {
    setUploaded(wrapper.file.size, wrapper.file.size)

    return wrapper.file
}

export default defineComponent({
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        append: {
            type: Boolean,
            default: false
        },
        maxFiles: {
            type: Number,
            default: Infinity
        },
        hint: {
            type: String,
            default: null
        },
        onUpload: {
            type: Function as PropType<UploadFunction>,
            default: defaultUploader
        },
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: () => []
        },
        name: {
            type: String,
            default: null
        },
        maxSize: {
            type: Number,
            default: 10485760
        },
        errors: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        allowedTypes: {
            type: Array as PropType<MIMEType[]>,
            default: () => [],
            validator: (value: MIMEType[]) => value.every(
                mimeType => allMimeTypes.includes(mimeType)
            )
        },
        single: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        transform: {
            type: Function as PropType<TransformCallback>,
            default: getUploadResult
        },
        useSelfInterface: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        let wasEmited = false
        const files = ref<FileWrapper[]>([])

        const onInput = () => {
            const value = props.multiple
                ? files.value
                : (files.value[0] || null)
            const transformed = props.transform
                ? props.transform(value)
                : value

            wasEmited = true

            emit('input', transformed)
            emit('change', { target: { value: transformed } })
            emit('on-input', transformed)
        }

        const filesCount = computed(() => (props.multiple ? props.maxFiles : 1))
        const upload = async (fileInfo: FileWrapper) => {
            try {
                fileInfo.uploadResult = await props.onUpload(
                    fileInfo,
                    (uploaded: number, total: number) => {
                        fileInfo.uploadedSize = uploaded
                        fileInfo.size = total
                    }
                )

                emit('on-uploaded', fileInfo)

                if (files.value.every(fileInfo => fileInfo.uploadResult !== undefined)) {
                    emit('on-all-uploaded', files.value)
                }

                onInput()
            } catch (error) {
                emit('on-error', error, fileInfo)
                console.error(error)
                fileInfo.error = error
            }
        }

        const addFiles = (newFiles: File[]) => {
            if (!props.append) {
                files.value = []
            }

            newFiles.forEach((file) => {
                const timestamp = new Date().getTime()
                const fileInfo: FileWrapper = {
                    file,
                    size: file.size,
                    timestamp,
                    id: `${file.name} (${timestamp})`,
                    uploadedSize: 0,
                    error: null,
                    uploader: null
                }

                fileInfo.uploader = upload(fileInfo)

                files.value.unshift(fileInfo)
            })

            files.value = files.value.slice(0, filesCount.value)

            emit('on-add', newFiles)
        }

        const remove = (index: number) => {
            emit('on-remove', ...files.value.splice(index, 1))
            onInput()
        }

        const retry = (index: number) => {
            const fileInfo = files.value[index]

            fileInfo.error = null
            fileInfo.uploadedSize = 0
            fileInfo.uploader = upload(fileInfo)

            emit('on-retry', fileInfo)
        }

        const onFileClick = (file: FileWrapper) => {
            emit('on-file-click', file)
        }

        const hideArea = computed(() => props.single && files.value.length >= 1)

        watch(
            () => props.value,
            () => {
                if (wasEmited) {
                    wasEmited = false

                    return undefined
                }

                const newFiles = Array.isArray(props.value)
                    ? props.value
                    : [ props.value ]

                files.value = newFiles.filter(Boolean).map(
                    (file, index) => toFileWrapper(file, String(index + 1))
                )
            },
            {
                immediate: true,
                deep: true
            }
        )

        return {
            files,
            addFiles,
            remove,
            retry,
            hideArea,
            onFileClick
        }
    }
})
