/* eslint-disable import/no-extraneous-dependencies */
import { Context } from '@nuxt/types'
import { ref } from '@nuxtjs/composition-api'
import { Components as Admin } from '@/types/client.admin'
import { openModal } from '@/composables/result-modal'
import { Merge } from '@/composables/joins'
import { useSinglePromise } from '@/composables/single-promise'
import {
    currentSegment,
    fetchParam,
    period,
    pickedManagersList,
    searchQuery
} from '@/composables/managers-office-common-fields'
import { useScrollableCollection } from '@/composables/scrollable-collection'

export type ExtendedCabinetStatus = Admin.Schemas.ExecutorCabinetStatus | 'Rejected'
export type ExtendedAdminCabinetDetails = Merge<
    Admin.Schemas.AdminExecutorCabinetDetails,
    Merge<Admin.Schemas.AdminExecutorCabinetDetails['cabinet'], { status: ExtendedCabinetStatus }>
>
export type ExtendedAdminExecutorCabinet = Merge<
    Admin.Schemas.AdminExecutorCabinet,
    { status: ExtendedCabinetStatus }
>

export type AdminExecutorCabinet = Merge<ExtendedAdminExecutorCabinet, ExtendedAdminCabinetDetails>

export const managerAdmins = ref<Admin.Schemas.UserShortInfo[]>([])
export const openedChannel = ref<string | null | undefined>(null)

export const fetchManagersMethod = ref()

export const fetchManagers = useSinglePromise(async (context: unknown) => {
    const { $apiAdmin } = context as Context

    try {
        const { data } = await $apiAdmin.usersGetAdmins()

        managerAdmins.value = data
    } catch (error) {
        console.error(error)
    }
})

export const useManagersOffice = (context: unknown) => {
    const { $apiAdmin } = context as Context

    const fetchChannelsArray = async (fromCursor?: string | null) => {
        const {
            data: { items = [], forwardCursor = null }
        } = await $apiAdmin.worklegramChannelsGetChannelMessagesInfo(null, {
            searchText: searchQuery.value,
            managers: pickedManagersList.value,
            startDate: period.value?.from,
            endDate: period.value?.to,
            fromCursor: fromCursor || undefined,
            isLongWaiting: fetchParam.value.isLongWaiting,
            isUnreaded: fetchParam.value.isUnreaded,
            segmentId: currentSegment.value,
            limit: 40
        })

        return {
            forwardCursor,
            items
        }
    }

    const {
        fetch,
        isLoading,
        items: products,
        attach,
        detach
    } = useScrollableCollection(fetchChannelsArray, {
        fetch: true
    })

    fetchManagersMethod.value = fetch

    return {
        fetch,
        isLoading,
        products,
        attach,
        detach
    }
}

export const useCabinets = (context: unknown) => {
    const { $apiAdmin } = context as Context

    const setStatus = (cabinet: AdminExecutorCabinet) => {
        const actualCabinet = cabinet.cabinet || cabinet

        actualCabinet.status = actualCabinet.status === 'Blocked' && !actualCabinet.activatedDate
            ? 'Rejected'
            : actualCabinet.status
    }

    const activateCabinet = async (cabinet: AdminExecutorCabinet) => {
        try {
            const actualCabinet = cabinet.cabinet || cabinet

            if (!actualCabinet.status || !actualCabinet.id) {
                throw new Error('There is no data for cabinet activate')
            }

            await $apiAdmin.executorCabinetsActivate(cabinet.id || cabinet.cabinet?.id)

            actualCabinet.status = 'Active'
            actualCabinet.activatedDate = new Date().toISOString()
        } catch (error) {
            console.error(error)

            openModal({ type: 'error' })
        }
    }

    const blockCabinet = async (cabinet: AdminExecutorCabinet, comment: string) => {
        const actualCabinet = cabinet.cabinet || cabinet

        if (!actualCabinet || !actualCabinet.comments) {
            throw new Error('There is no data for cabinet block')
        }

        await $apiAdmin.executorCabinetsBlock(actualCabinet.id, { comment })

        actualCabinet.status = 'Blocked'
        actualCabinet.comments.block = {
            createdDate: new Date().toISOString(),
            text: comment
        }
    }

    return {
        activateCabinet,
        blockCabinet,
        setStatus
    }
}
