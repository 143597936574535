
















import {
    defineComponent,
    onMounted,
    ref,
    useContext
} from '@nuxtjs/composition-api'
import { fetchCompany, partner } from '@/composables/partner'
import { fetchUser, user } from '@/composables/user'
import { ReadCallback, SendCallback, FetchCallback } from '@/types/chat'
import AppChatModal from '@/components/ui/AppChatModal.vue'

export default defineComponent({
    setup: () => {
        const context = useContext()
        const { $apiPartner } = context

        const $modal = ref<InstanceType<typeof AppChatModal> | null>(null)
        const open = () => $modal.value?.open()
        const close = () => $modal.value?.close()

        const fetchMessages: FetchCallback = async (fromCursor) => {
            const {
                data: { forwardCursor = null, items = [] }
            } = await $apiPartner.portalChatQuery(null, {
                fromCursor,
                limit: 50,
                partnerId: partner.value.company?.id
            })

            return {
                forwardCursor,
                items: items.reverse()
            }
        }
        const readMessage: ReadCallback = ids => $apiPartner.portalChatMarkMessagesRead(null, {
            chatMessageIds: ids.map(id => ({ id }))
        })
        const sendMessage: SendCallback = text => $apiPartner.portalChatSendMessage(null, {
            message: text,
            partnerId: partner.value.company?.id
        })

        onMounted(() => {
            fetchUser(context)
            fetchCompany(context)
        })

        return {
            $modal,
            fetchMessages,
            readMessage,
            sendMessage,
            open,
            close,
            partner,
            user
        }
    }
})
