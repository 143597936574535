var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-dropdown', _vm._b({
    ref: "$dropdown",
    staticClass: "app-dropdown-menu",
    attrs: {
      "flip": "both",
      "manual": "",
      "tabindex": "1"
    },
    nativeOn: {
      "focusin": function ($event) {
        return _vm.open.apply(null, arguments);
      },
      "focusout": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (defaultScope) {
        return [_c('button', {
          staticClass: "app-dropdown-menu__handler",
          on: {
            "click": _vm.toggle,
            "mousedown": [function ($event) {
              $event.preventDefault();
            }, function ($event) {
              $event.stopPropagation();
            }],
            "mousemove": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._t("default", null, null, defaultScope)], 2)];
      }
    }, {
      key: "content",
      fn: function (contentScope) {
        return [_c('div', {
          staticClass: "app-dropdown-menu__list",
          style: _vm.listStyles,
          on: {
            "mousedown": function ($event) {
              $event.preventDefault();
            },
            "mouseenter": _vm.mouseEnter,
            "mouseleave": _vm.mouseLeave
          }
        }, _vm._l(_vm.displaySections, function (section, index) {
          return _c('section', {
            key: index,
            staticClass: "app-dropdown-menu__section",
            style: _vm.sectionStyles
          }, _vm._l(section, function (item) {
            var _class;

            return _c('div', {
              key: item.alias,
              class: (_class = {
                'app-dropdown-menu__section-item': true
              }, _class[("app-dropdown-menu__section-item--" + (item.color))] = true, _class['app-dropdown-menu__section-item--highlighted'] = item.alias === _vm.highlighted && !_vm.mouseIn, _class),
              on: {
                "click": function ($event) {
                  return _vm.onItemClick(item);
                },
                "mousemove": function ($event) {
                  $event.stopPropagation();
                },
                "mousedown": function ($event) {
                  $event.stopPropagation();
                }
              }
            }, [_c('div', {
              class: ['app-dropdown-menu__section-item-background', ("app-dropdown-menu__section-item-background--" + (item.color))]
            }), _vm._v(" "), _vm._t("item", function () {
              return [_vm._v("\n                        " + _vm._s(item.name) + "\n                    ")];
            }, {
              "item": item
            }, Object.assign({}, contentScope, {
              item: item
            }))], 2);
          }), 0);
        }), 0)];
      }
    }], null, true)
  }, 'base-dropdown', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }