var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['app-list-buttons', {
      'app-list-buttons__reverse': _vm.reverse
    }]
  }, [!_vm.hideDelete ? _c('app-list-button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('on-delete');
      }
    }
  }, [_c('close-icon', {
    staticClass: "app-list-buttons__icon-close"
  })], 1) : _vm._e(), _vm._v(" "), _vm._t("additional"), _vm._v(" "), !_vm.hideMove ? [_c('app-list-button', {
    attrs: {
      "disabled": _vm.disabled.up
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('on-move-up');
      }
    }
  }, [_c('down-arrow-icon', {
    staticClass: "app-list-buttons__icon-up"
  })], 1), _vm._v(" "), _c('app-list-button', {
    attrs: {
      "disabled": _vm.disabled.down
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('on-move-down');
      }
    }
  }, [_c('down-arrow-icon', {
    staticClass: "app-list-buttons__icon-down"
  })], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }