











































































import { computed, defineComponent } from '@nuxtjs/composition-api'
import { formatBytes } from '@/composables/utils'

import FileIcon from '@/assets/img/file.svg'
import CheckIcon from '@/assets/img/clear-check.svg'
import CrossIcon from '@/assets/img/close.svg'
import RetryIcon from '@/assets/img/retry.svg'

export default defineComponent({
    components: {
        FileIcon,
        CheckIcon,
        CrossIcon,
        RetryIcon
    },
    props: {
        size: {
            type: Number,
            default: 0
        },
        uploadedSize: {
            type: Number,
            default: 0
        },
        name: {
            type: String,
            default: 'Безымянный'
        },
        error: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const isUploaded = computed(() => props.uploadedSize === props.size)

        const fileIcon = computed(() => isUploaded.value ? 'check-icon' : 'file-icon')
        const actionIcon = computed(() => props.error ? 'retry-icon' : 'cross-icon')

        const formattedSize = computed(() => formatBytes(props.size))
        const formattedUploadSize = computed(() => formatBytes(props.uploadedSize))

        const progressSize = computed(
            () => props.uploadedSize / props.size * 100
        )

        const action = () => {
            if (props.disabled) return undefined
            if (props.error) return emit('on-retry')

            emit('on-cancel')
        }

        const onClick = () => {
            if (props.disabled) return undefined

            emit('on-click')
        }

        return {
            fileIcon,
            progressSize,
            formatBytes,
            isUploaded,
            actionIcon,
            action,
            formattedSize,
            formattedUploadSize,
            onClick
        }
    }
})
