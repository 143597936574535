































import { computed, defineComponent } from '@nuxtjs/composition-api'
import DownArrowIcon from '@/assets/img/arrow-down.svg'
import CloseIcon from '@/assets/img/close.svg'

import AppListButton from './-AppListButton.vue'

export default defineComponent({
    components: {
        DownArrowIcon,
        CloseIcon,
        AppListButton
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        disabledFirst: {
            type: Boolean,
            default: false
        },
        disabledLast: {
            type: Boolean,
            default: false
        },
        count: {
            type: Number,
            required: true
        },
        hideDelete: {
            type: Boolean,
            default: false
        },
        hideMove: {
            type: Boolean,
            default: false
        },
        reverse: {
            type: Boolean,
            default: false
        }
    },
    setup: (props) => {
        const disabled = computed(() => {
            const {
                count,
                disabledFirst,
                disabledLast,
                index
            } = props

            const upCondition = index === 0
                || (disabledFirst && index === 1)
                || (disabledLast && index === count - 1)

            const downCondition = index === count - 1
                || (disabledFirst && index === 0)
                || (disabledLast && index === count - 2)

            return {
                up: upCondition,
                down: downCondition
            }
        })

        return { disabled }
    }
})
