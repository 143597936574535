













import { PropType, defineComponent } from '@nuxtjs/composition-api'

import { CatalogItem as CatalogItemInterface } from '@/interfaces/catalog.interface'
import { closeAside } from '@/composables/asides'

export default defineComponent({
    props: {
        catalogItems: {
            type: Array as PropType<CatalogItemInterface[]>,
            default: () => []
        }
    },
    setup: () => {
        const onClick = (item: CatalogItemInterface) => {
            if (item.nested?.length) return undefined

            closeAside()
        }

        return {
            onClick
        }
    }
})
