








































































import {
    defineComponent,
    ref,
    PropType,
    computed
} from '@nuxtjs/composition-api'
import BaseDropdown from '@/components/ui/BaseDropdown.vue'

export interface AppDropdownMenuSectionItem {
    name: string
    alias: string
    color?: string
}

export type AppDropdownMenuSection = AppDropdownMenuSectionItem[]

const isAppDropdownMenuSectionItem = (item: any): item is AppDropdownMenuSectionItem =>
    typeof item?.name !== 'string'

const isAppDropdownMenuSection = (item: any): item is AppDropdownMenuSection =>
    Array.isArray(item) && item.every(isAppDropdownMenuSectionItem)

export default defineComponent({
    props: {
        sections: {
            type: Array as PropType<AppDropdownMenuSection[]>,
            validator: isAppDropdownMenuSection,
            required: true
        },
        hideItems: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        displayItems: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        manual: {
            type: Boolean,
            default: false
        },
        arrow: {
            type: Boolean,
            default: true
        },
        width: {
            type: Number,
            default: null
        },
        sectionPadding: {
            type: Number,
            default: null
        },
        preventHide: {
            type: Boolean,
            default: false
        },
        highlighted: {
            type: String,
            default: ''
        },
        listGap: {
            type: Number,
            default: 10
        }
    },
    setup: (props, { emit }) => {
        const $dropdown = ref<InstanceType<typeof BaseDropdown> | null>(null)
        const open = () => {
            $dropdown.value?.open()
            $dropdown.value?.$element?.focus()
            emit('on-open')
        }
        const close = () => {
            $dropdown.value?.close();
            (document.activeElement as HTMLElement).blur()
            emit('on-close')
        }
        const toggle = () => ($dropdown.value?.isOpen ? close() : open())

        const sectionStyles = computed(() => {
            const paddingRight
                = props.sectionPadding !== null ? `${props.sectionPadding}px` : ''

            return { paddingRight }
        })

        const listStyles = computed(() => ({
            marginTop: `${props.listGap}px`
        }))

        const onItemClick = (item: AppDropdownMenuSectionItem) => {
            emit('on-click', item)
            emit(`on-${item.alias}`)

            if (!props.preventHide) {
                close()
            }
        }

        const displaySections = computed(() => {
            if (!props.displayItems.length && !props.hideItems.length) {
                return props.sections
            }

            return props.sections.reduce((displaing, section) => {
                const sectionItems = section.filter((item) => {
                    if (props.hideItems.length) {
                        return !props.hideItems.includes(item.alias)
                    }

                    if (props.displayItems.length) {
                        return props.displayItems.includes(item.alias)
                    }

                    return true
                })

                if (!sectionItems.length) return displaing

                return displaing.concat([ sectionItems ])
            }, [] as AppDropdownMenuSection[])
        })

        const mouseIn = ref(false)
        const mouseEnter = () => {
            mouseIn.value = true
        }
        const mouseLeave = () => {
            mouseIn.value = false
        }

        return {
            $dropdown,
            close,
            displaySections,
            listStyles,
            mouseEnter,
            mouseIn,
            mouseLeave,
            onItemClick,
            open,
            sectionStyles,
            toggle
        }
    }
})
