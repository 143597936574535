var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M1.458 8.333a1 1 0 011 1v2.328l.001.002a.19.19 0 00.05.056.6.6 0 00.371.114h8.532a.6.6 0 00.37-.114.189.189 0 00.05-.056l.001-.002V9.333a1 1 0 112 0v2.334c0 .645-.314 1.214-.782 1.598a2.593 2.593 0 01-1.64.568h-8.53c-.586 0-1.178-.189-1.64-.568-.469-.384-.783-.953-.783-1.598V9.333a1 1 0 011-1z"
    }
  }), _c('path', {
    attrs: {
      "d": "M6.292.46a1 1 0 011.415 0l2.333 2.333a1 1 0 01-1.414 1.414L8 3.581v5.752a1 1 0 11-2 0V3.58l-.627.627A1 1 0 113.96 2.793L6.292.46z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }