var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "catalog-profile"
  }, [_c(_vm.linkType, {
    tag: "component",
    staticClass: "catalog-profile__avatar-link",
    attrs: {
      "to": _vm.profileLink,
      "href": _vm.profileLink
    }
  }, [_c('app-avatar', {
    staticClass: "catalog-profile__avatar",
    attrs: {
      "name": _vm.userFullName
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "catalog-profile__info"
  }, [_c(_vm.linkType, {
    tag: "component",
    staticClass: "catalog-profile__name",
    attrs: {
      "to": _vm.profileLink,
      "href": _vm.profileLink
    },
    domProps: {
      "innerHTML": _vm._s(_vm.displayName)
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }