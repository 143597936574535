var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('menu-layout', {
    staticClass: "partner",
    attrs: {
      "profile-link": "/office/profile",
      "logo-link": "/office",
      "hide-search": ""
    },
    scopedSlots: _vm._u([{
      key: "aside-catalog",
      fn: function () {
        return [_c('the-mobile-catalog', {
          staticClass: "partner__aside-catalog",
          attrs: {
            "catalog-items": _vm.catalog,
            "profile-link": _vm.profileLink
          }
        })];
      },
      proxy: true
    }, {
      key: "aside-profile",
      fn: function () {
        return [_c('the-mobile-profile', {
          staticClass: "partner__aside-profile",
          attrs: {
            "profile-link": _vm.profileLink
          }
        })];
      },
      proxy: true
    }, {
      key: "catalog",
      fn: function () {
        return [_c('the-catalog', {
          staticClass: "partner__catalog",
          attrs: {
            "catalog-items": _vm.catalog,
            "profile-link": _vm.profileLink
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('partner-chat-modal', {
          ref: "$partnerChatModal",
          staticClass: "partner__chat-modal",
          on: {
            "on-close": _vm.clearHash,
            "on-read": _vm.read
          }
        }), _vm._v(" "), _c('nuxt')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }