

















































































import {
    computed,
    defineComponent,
    PropType,
    ref
} from '@nuxtjs/composition-api'
import { formatBytes } from '@/composables/utils'

import UploadIcon from '@/assets/img/upload.svg'
import ErrorIcon from '@/assets/img/info.svg'
import { allMimeTypes, fileExtensions, MIMEType } from '@/composables/mime-types'

export default defineComponent({
    components: {
        UploadIcon,
        ErrorIcon
    },
    props: {
        value: {
            type: Array as PropType<Array<File>>,
            default: () => []
        },
        multiple: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            default: null
        },
        maxSize: {
            type: Number,
            default: 10485760
        },
        name: {
            type: String,
            default: null
        },
        errors: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowedTypes: {
            type: Array as PropType<MIMEType[]>,
            default: () => [],
            validator: (value: MIMEType[]) => value.every(
                mimeType => allMimeTypes.includes(mimeType)
            )
        },
        wide: {
            type: Boolean,
            default: false
        },
        useSelfInterface: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const $fileInput = ref<HTMLInputElement | null>(null)
        const openDialog = () => {
            $fileInput.value?.click()
        }

        const allowedextensions = computed(() => {
            const extensions = props.allowedTypes.flatMap(
                type => fileExtensions[type].map(extension => `*${extension}`)
            )

            return Array.from(new Set(extensions)).join(', ')
        })

        const filesCaption = computed(() => (props.multiple ? 'файлы' : 'файл'))
        const fileSizeError = computed(
            () => `Выберите файл размером до ${formatBytes(props.maxSize)}`
        )
        const fileTypeError = computed(
            () => `Выберите ${filesCaption.value} типа: ${allowedextensions.value}`
        )

        const draggedIn = ref(false)
        const enterCounter = ref(0)
        const onDragEnter = () => {
            ++enterCounter.value

            draggedIn.value = true
        }
        const onDragLeave = () => {
            --enterCounter.value

            if (enterCounter.value === 0) {
                draggedIn.value = false
            }
        }

        const selfErorrs = ref<string[]>([])

        const addFiles = (event: Event | DragEvent) => {
            const errorsSet = new Set(selfErorrs.value)
            const isDrag = event instanceof DragEvent

            errorsSet.clear()

            if (isDrag) {
                draggedIn.value = false
                enterCounter.value = 0
            }

            const fileList: FileList | null | undefined = isDrag
                ? event.dataTransfer?.files
                : (event.target as HTMLInputElement)?.files

            if (!fileList) return undefined

            const filesArray = Array.from(fileList).reduce((files, file) => {
                const currentErrors = []

                if (file.size > props.maxSize) {
                    currentErrors.push(fileSizeError.value)
                }

                if (
                    props.allowedTypes.length
                    && !props.allowedTypes.includes(file.type as MIMEType)
                ) {
                    currentErrors.push(fileTypeError.value)
                }

                if (currentErrors.length) {
                    currentErrors.forEach(error => errorsSet.add(error))

                    return files
                }

                files.push(file)

                return files
            }, [] as File[])

            if ($fileInput.value) {
                $fileInput.value.value = ''
                $fileInput.value.files = null
            }

            selfErorrs.value = Array.from(errorsSet)
            emit('input', filesArray)
        }

        const totalErrors = computed(() => props.errors.concat(selfErorrs.value))

        const defaultHint = computed(() => {
            if (props.useSelfInterface) {
                return undefined
            }

            return `Размер файла не должен превышать ${formatBytes(props.maxSize)}`
        })

        return {
            $fileInput,
            openDialog,
            draggedIn,
            onDragEnter,
            onDragLeave,
            addFiles,
            totalErrors,
            selfErorrs,
            filesCaption,
            formatBytes,
            defaultHint
        }
    }
})
