





























































import { defineComponent, PropType } from '@nuxtjs/composition-api'

import PlusCircleIcon from '@/assets/img/plus-circle.svg'

export default defineComponent({
    components: {
        PlusCircleIcon
    },
    props: {
        value: {
            type: Array as PropType<unknown[]>,
            required: true
        },
        newValue: {
            type: [ Object, Array, String, Number ] as PropType<unknown | null>,
            default: null
        },
        hideButton: {
            type: Boolean,
            default: false
        },
        disableButton: {
            type: Boolean,
            default: false
        },
        hideDelete: {
            type: Boolean,
            default: false
        },
        movable: {
            type: Boolean,
            default: false
        },
        buttonCaption: {
            type: String,
            default: 'Добавить'
        },
        revertList: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const onInput = (index: number, value: unknown) => {
            const copy = structuredClone(props.value)

            copy[index] = value

            emit('input', copy)
        }

        const addItem = () => {
            emit('on-add')

            if (!props.newValue) {
                return undefined
            }

            const copy = structuredClone(props.newValue)
            const withNew = props.revertList
                ? [ copy ].concat(props.value)
                : props.value.concat(copy)

            emit('input', withNew)
        }

        const deleteItem = (index: number) => {
            if (!props.value.length) {
                return undefined
            }

            if (props.value.length === 1) {
                return emit('input', [])
            }

            emit('input', props.value.slice(0, index).concat(props.value.slice(index + 1)))
        }

        const moveItem = (from: number, to: number) => {
            const copy = structuredClone(props.value)

            // eslint-disable-next-line prefer-destructuring
            copy[from] = copy.splice(to, 1, copy[from])[0]

            emit('input', copy)
        }

        return {
            onInput,
            addItem,
            deleteItem,
            moveItem
        }
    }
})
