import { render, staticRenderFns } from "./-CatalogLogoutButton.vue?vue&type=template&id=7cd2821f&"
import script from "./-CatalogLogoutButton.vue?vue&type=script&lang=ts&"
export * from "./-CatalogLogoutButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CatalogItem: require('/var/www/app/src/components/pages/TheCatalog/CatalogItem.vue').default})
