var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat-messages-list",
    on: {
      "wheel": function ($event) {
        $event.preventDefault();
        return _vm.onWheel.apply(null, arguments);
      }
    }
  }, [_c('div', {
    ref: "$list",
    staticClass: "chat-messages-list__content-wrapper",
    on: {
      "scroll": _vm.onScroll
    }
  }, [_c('div', {
    staticClass: "chat-messages-list__messages-wrapper"
  }, [_vm.loading ? _c('app-spinner', {
    staticClass: "chat-messages-list__loader"
  }) : _vm._e(), _vm._v(" "), !_vm.messages.length && !_vm.loading ? _c('div', {
    staticClass: "chat-messages-list__no-messages"
  }, [_vm._v("\n                Сообщений нет\n            ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.chatList, function (dateGroup) {
    return _c('div', {
      key: dateGroup.date,
      staticClass: "chat-messages-list__date-group"
    }, [_vm._t("date", function () {
      return [_c('div', {
        staticClass: "chat-messages-list__date"
      }, [_vm._v("\n                        " + _vm._s(_vm.dateToString(dateGroup.date)) + "\n                    ")])];
    }, null, {
      isoDate: dateGroup.date,
      formattedDate: _vm.dateToString(dateGroup.date)
    }), _vm._v(" "), _vm._l(dateGroup.messagesGroups, function (messageGroup) {
      var _messageGroup$from, _messageGroup$from2, _messageGroup$from5;

      return _c('div', {
        key: messageGroup.messages[0].id,
        class: ['chat-messages-list__messages-group', {
          'chat-messages-list__messages-group--info': messageGroup.messageType === 'Info'
        }, {
          'chat-messages-list__messages-group--right': _vm.userId === ((_messageGroup$from = messageGroup.from) === null || _messageGroup$from === void 0 ? void 0 : _messageGroup$from.id)
        }]
      }, [messageGroup.status === 'Unread' && !_vm.hideUnreadCaption ? _c('div', {
        ref: "$newCaption",
        refInFor: true,
        staticClass: "chat-messages-list__new-messages"
      }, [_vm._t("new-messages-caption", function () {
        return [_c('div', {
          staticClass: "chat-messages-list__new-caption"
        }, [_vm._v("\n                                Новые сообщения\n                            ")])];
      })], 2) : _vm._e(), _vm._v(" "), messageGroup.messageType !== 'Info' ? _c('div', {
        staticClass: "chat-messages-list__name-wrapper"
      }, [_vm._t(_vm.userId === ((_messageGroup$from2 = messageGroup.from) === null || _messageGroup$from2 === void 0 ? void 0 : _messageGroup$from2.id) ? 'right-name' : 'left-name', function () {
        var _messageGroup$from3, _messageGroup$from3$p, _messageGroup$from4, _messageGroup$from4$p;

        return [_c('div', {
          staticClass: "chat-messages-list__name"
        }, [_vm._v("\n                                " + _vm._s((_messageGroup$from3 = messageGroup.from) === null || _messageGroup$from3 === void 0 ? void 0 : (_messageGroup$from3$p = _messageGroup$from3.profile) === null || _messageGroup$from3$p === void 0 ? void 0 : _messageGroup$from3$p.name) + "\n                                " + _vm._s((_messageGroup$from4 = messageGroup.from) === null || _messageGroup$from4 === void 0 ? void 0 : (_messageGroup$from4$p = _messageGroup$from4.profile) === null || _messageGroup$from4$p === void 0 ? void 0 : _messageGroup$from4$p.familyName) + "\n                            ")])];
      }, null, (_messageGroup$from5 = messageGroup.from) === null || _messageGroup$from5 === void 0 ? void 0 : _messageGroup$from5.profile)], 2) : _vm._e(), _vm._v(" "), _vm._l(messageGroup.messages, function (message) {
        return _c('div', {
          key: message.id,
          class: ['chat-messages-list__message-wrapper', {
            'chat-messages-list__info-message-wrapper': message.messageType === 'Info'
          }]
        }, [_vm._t(_vm.getMessageType(messageGroup), null, null, message)], 2);
      })], 2);
    })], 2);
  })], 2)]), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.scrolledSize > 100 ? _c('button', {
    staticClass: "chat-messages-list__to-end",
    on: {
      "click": function ($event) {
        return _vm.scrollToBottom(true);
      }
    }
  }, [_c('down-arrow-icon', {
    staticClass: "chat-messages-list__to-end-icon"
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }