var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['chat-message', ("chat-message--" + (_vm.type))],
    on: {
      "contextmenu": function ($event) {
        $event.preventDefault();
        return _vm.$emit('on-open-contextmenu', $event);
      }
    }
  }, [_vm.message.respondedTo ? _c('chat-message-quote', {
    staticClass: "chat-message__quote",
    attrs: {
      "title": _vm.respondedTitle
    }
  }, [_vm._v("\n        " + _vm._s(_vm.responededMessage) + "\n    ")]) : _vm._e(), _vm._v(" "), !_vm.fileParams.isFile ? _c('div', {
    staticClass: "chat-message__text",
    domProps: {
      "innerHTML": _vm._s(_vm.formattedText)
    }
  }) : _c('div', [_vm.fileParams.fileType === 'image' ? _c('div', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": String(_vm.fileParams.fileLink)
    }
  }, [_c('img', {
    staticClass: "chat-message__image",
    attrs: {
      "src": String(_vm.fileParams.fileLink)
    }
  })])]) : _vm._e(), _vm._v(" "), _vm.fileParams.fileType === 'file' ? _c('div', [_c('div', {
    staticClass: "chat-message__file-block",
    on: {
      "click": function ($event) {
        _vm.openWarnModal(String(_vm.fileParams.fileLink));
      }
    }
  }, [_c('file-icon', {
    staticClass: "chat-message__file-icon"
  }), _vm._v("\n                " + _vm._s(_vm.fileParams.sourceFileName) + "\n            ")], 1)]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "chat-message__time"
  }, [_vm._t("dateCreated", function () {
    return [_vm._v("\n            " + _vm._s(_vm.isEdited ? 'изменено в' : '') + " " + _vm._s(_vm.formattedTime) + "\n        ")];
  }, null, {
    dateCreated: _vm.message.dateCreated
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }